.language_main_div{
    padding: 50px;
}
.language_main_div, .language_sub_div_1, .language_conte_div, .language_final p{
    text-align: justify;
    line-height: 1.9;
    font-size: 15px;
       font-family: "Poppins", Sans-serif;
}
.language_pic img{
    width: 100%;
}
.language_sub_div{
    display: flex;
    padding: 50px;
    margin-top: -5%;
    gap:50px;
}
.language_sub_div_1{
    width: 100%;
}
.language_conte_div{
    padding: 50px;
    margin-top: -10%;
}
.language_conte_div h2, .langiage_correct_div h2{
    font-size: 20px;
    font-weight: 400;
}
.lang_grd_div{
    padding: 50px;
    margin-top: -5%;
    display: flex;
    gap:20%;
}
.lang_add_pic img{
    margin-top: 20%;
    width: 100%;
}
.langiage_correct_div li{
    text-align: justify;
    line-height: 1.9;
    font-size: 15px;
       font-family: "Poppins", Sans-serif;
}
.language_final{
    padding: 50px;
    margin-top: -5%;
}

@media only screen and (max-width: 768px) {
    .language_main_div,
    .language_sub_div,
    .language_conte_div,
    .lang_grd_div,
    .language_final {
        padding: 20px; /* Reduced padding for smaller screens */
    }

    .language_sub_div {
        display: flex;
        flex-direction: column; /* Change to column layout for smaller screens */
        gap: 20px; /* Adjusted gap for smaller screens */
    }

    .language_sub_div_1 {
        width: 100%; /* Adjusted width for full width on smaller screens */
    }

    .language_pic img {
        width: 100%; /* Make image responsive on smaller screens */
    }

    .lang_add_pic img {
        margin-top: 10%; /* Adjusted margin-top for smaller screens */
        width: 100%; /* Make image responsive on smaller screens */
    }

    .lang_grd_div {
        display: grid;
        gap: 10%; /* Adjusted gap for smaller screens */
    }

    .langiage_correct_div li {
        font-size: 16px; /* Decreased font size for smaller screens */
    }
    .lag_pic_div img{
        height: 200px !important;
    }
}

.lag_pic_div img{
    width: 100%;
    height: 450px;
}