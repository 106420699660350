.java_main_div img{
    width: 100%;
    height: 450px;
}
.java_cont_div{
    display: flex;
    gap:20%;
}
.java_dig_div{
    padding: 50px;
    display: flex;
    gap:20px;
}
.java_cont_div , .java_supoort_1 li{
    line-height: 1.9;
    text-align: justify;
    font-family: "Poppins", Sans-serif;
    font-size: 15px;
}
.java_gid_pic img{
    width: 100%;
 
    border-radius: 6px;
}
.java_back_div{
    padding: 50px;
    margin-top: -5%;
}
.java_supoort{
    padding: 50px;
    margin-top: -5%;
}
.java_spoty{
    padding: 50px;
    margin-top: -5%;
}
.java_div_main{
    width: 100%;
   
}

.java_back_div h2,
.java_supoort h2{
    font-size: 20px;
    color: #066fb2;
    font-weight:400 ;
}
.java_div_main h2{
    color: #066fb2;
}
.java_spoty, .java_div_main , .java_div_lin_1 p{
    line-height: 1.9;
    text-align: justify;
    font-family: "Poppins", Sans-serif;
    font-size: 15px;
}
.ja_pic img{
    width: 100%;
    margin-left: 20%;
    margin-top: 20%;
   
}
.java_supoort{
    display: flex;
    gap:20px;
}

@media only screen and (max-width: 768px) {
    .java_cont_div {
        flex-direction: column;
        gap: 10%;
    }

    .java_dig_div {
        flex-direction: column;
        gap: 10px;
        padding: 30px; /* Reduced padding for smaller screens */
    }

   

    .java_back_div,
    .java_supoort,
    .java_spoty {
        padding: 30px; /* Reduced padding for smaller screens */
        margin-top: -5%;
    }

    .ja_pic img {
        margin-left: 0; /* Center align image on smaller screens */
    }
    .java_supoort{
        display: grid;
    }
    .java_main_div img{
        height: 200px;
    }
    .java_div_lin_1{
        padding: 20px;
    }
    .ja_pic img{
        margin-top: 0px;
    }
}


.java_div_lin_1{
    padding: 50px;
    margin-top: -5%;
}


@media only screen and (max-width: 768px) {
    .java_div_lin_1 {
        padding: 20px;
        border-radius: 0;
    }

    .java_div_lin_1 p {
        margin-bottom: 10px;
    }
}

.body{
    overflow-x: hidden;
}