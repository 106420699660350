@media only screen and (max-width: 768px) {
    .seasense_head {
        top: 10%;
        left: 10%;
        padding: 10px;
    }

    .seasense_menus {
        flex-direction: column;
        align-items: flex-start;
    }

    .seasense_menus label {
        margin-right: 0;
        margin-bottom: 10px;
    }


    .content-overlay {
        top: 25%;
        left: 50%;
    }

    .logo {
        top: 60%;
        left: 50%;
    }

    .content_sub {
        top: 65%;
        left: 50%;
    }

    .content_sub p {
        width: 150%;
        margin-left: -50px;
        text-align: justify;
        margin-top: -50%;
    }
.welcom_main_div_1{
    width: 100%;
    display: grid;
}
 
    .service_main_div {
        flex-direction: column;
        align-items: center;
    }

    .service_div {
        width: 65% !important;
        margin-left: -10px !important;
    }

    .service_div h2 {
        font-size: 14px;
    }

    .service_div p {
        font-size: 12px;
    }

    .project_service_1 h2 {
        font-size: 30px;
    }

    .card {
        padding: 1rem;
    }
    .content-overlay h2{
        font-size: 25px;
        width: 150%;
        margin-left: -50px;
    }
    .content-overlay p{
        font-size: 15px;
    text-align: justify;
    font-weight: 900;
    width: 150%;
    margin-left: -50px;
    }
    .clint {
        flex-direction: column;
        align-items: center;
        padding: 50px;
    }

    .clint_1 {
        margin-top: 20px;
    }

    .clint_div_1 h2 {
        font-size: 20px; /* Adjust font size for mobile */
    }

    .clint_div_1 p {
        font-size: 12px; /* Adjust font size for mobile */
    }
    .software_content {
        flex-direction: column;
    }

    .software_sub_contant,
    .software_research_img {
        width: 100%;
        padding: 10px;
        text-align: center;
    }

    .software_sub_contant h2 {
        font-size: 20px;
    }

    .software_sub_contant p {
        font-size: 14px;
        width: 95%;
    }
    .seasense_cotent_1,
    .contact_div {
        padding: 10px;
        display: grid;
        margin-left: -5px;
    }

    .seasense_cotent_1 h2 {
        font-size: 20px;
    }

    .seasense_cotent_1 p {
        font-size: 14px;
    }

    .contact_div_1 {
        width:100%;
        margin-left: -25px;
    }
    .con{
        height: auto;
    }
    .hero-header {
        padding: 10px;
        margin-left: -10px;
    }

    .back_prin {
        flex-direction: column;
        align-items: center;
        margin-left: 10px;
    }

    .company_prin {
        flex-direction: column;
        gap: 20px;
        text-align: center;
    }

    .princple_img {
        width: 100%;
        order: 2;
    }

    .princple_img img {
        max-width: 100%;
        margin-left: 0px !important;
    }

    .company_princple {
        width: 100%;
        order: 1;
    }

    .company_princple h2 {
        font-size: 20px;
    }

    .company_princple p {
        font-size: 14px;
    }
    .company_princple {
         width: 100%; 
        padding: 15px;
    }
    .commete_div {
        flex-direction: column;
        padding: 20px;
      }
    
      .commite_main_div,
      .commite_main_div_1 {
        margin-top: 20px;
      }
    
      .commite_main_div h2,
      .commite_main_div_1 h2 {
        font-size: 16px;
      }
    
      .commite_main_div img,
      .commite_main_div_1 img {
        width: 50%;
      }
    
      .commite_main_div img,
      .commite_main_div_1 img {
        margin: auto;
        margin-top: 10px;
      }
    
      .commete_div_1 {
        margin-left: 0;
        margin-right: 0;
      }



      .commete_div_1 {
        flex-direction: column;
        gap: 20px;
        margin-left: 0;
        margin-right: 0;
        padding: 20px;
        margin-top: 0;
      }
    
      .commite_main_div_1 {
        height: auto;
        padding: 10px;
        margin-left: -17px !important;
      }
    
      .commite_main_div_1 h2 {
        font-size: 16px;
      }
    
      .commite_main_div_1 img {
        width: 50%;
      }
    
      .commite_main_div_1 img {
        margin: auto;
        margin-top: 10px;
      }

      .fainal_div_1 h2 {
        font-size: 20px;
      }
    
      .fainal_div_1 p {
        font-size: 14px;
        width: 150%;
        margin-left: -50px;
        text-align: justify !important;
      }
    
      .fainal_btn {
        margin-top: 15px;
      }
      .wave{
        margin-top: -10%;
      }
      .dd_phd {
        font-size: 16px; 
        display: grid !important;
        gap:20px;
      }
      .wlcm_div_content p{
        width: 100% !important;
      }
      .wlcom_search img{
        margin-left: 0px !important;
        width: 100% !important;
      }
     .company_princples{
        margin-left: -25px !important;
     }
    
        .contact_div_1 {
    transform: none; 
    height: auto;
    align-items: stretch; 
   
  }
  .ocean{
    display: none;
  }
  .contact_div {
    display: grid;
    justify-content: space-around;
    opacity: 2;
    transform: translateY(20px);
    position: relative;
}
.text-container {
    padding: 20px;
  }

  .MoveTop {
    display: flex;
    flex-direction: column;
  }

  .compyy_card_div {
    margin: 0;
  }
  .sub_co {
    flex-direction: column;
  }
.sub_co_cont_div{
  padding: 10px;
  width: 90%;
} 
.sub_co_cont_div img{
  width: 100%;
}
.pro_sub_div p{
  text-align: justify !important;
}
 
.advance_code_div {

  flex-direction: column; /* Stack items vertically */
  align-items: center; /* Center items horizontally */
  padding: 20px; /* Reduce padding for smaller screens */
}

.advnce_div_cod p {
  margin-top: 10px; /* Reduce top margin for smaller screens */
}

.advnce_pic {
  flex-wrap: wrap; /* Wrap images for smaller screens */
  justify-content: center; /* Center images horizontally */
}

.advnce_pic img {
  width: 45%; /* Adjust image width for smaller screens */
}
.advnce_pic img {
  width: 100% !important;
}
.commite_main_div_1{
  width: 106% !important;
  height: 100px !important;
}
.sub_co_div img{
  width: 100% !important;
}
 
}