.thesis_pic img{
    width: 100%;
    height: 450px;
}

.thesis_div h2{
    color: #066fb2;
}
.thesis_sub_div h2{
    color: #066fb2;
    font-size: 20px;
    font-weight: 400;
}
.thesis-guide_line h2{
    color: #066fb2;
    font-size: 20px;
    font-weight: 400;
}
.thesis_succes h2{
    color: #066fb2;
    font-size: 20px;
    font-weight: 400;
}
.thesis_div p{
line-height: 1.9;
text-align: justify;
font-family: "Poppins", Sans-serif;
font-size: 15px;
}
.thesis_sub_div{
    padding: 50px;
    margin-top: -5%;
}
.thesis_sub_div p{
    line-height: 1.9;
text-align: justify;
font-family: "Poppins", Sans-serif;
font-size: 15px;
}
.thesis-guide_line{
    padding: 50px;
    margin-top: -5%;
    width: 100%;
}
.thesis-guide_line_1 h2{
    font-size: 15px;
    color: #066fb2;
}
.thesis-guide_line_1 p{
    line-height: 1.9;
    text-align: justify;
    font-family: "Poppins", Sans-serif;
    font-size: 15px;
}
.thesis_succes{
    padding: 50px;
    margin-top: -5%;
}
.thesis_succes p{
    line-height: 1.9;
    text-align: justify;
    font-family: "Poppins", Sans-serif;
    font-size: 15px;
}
.thes_pic_div{
    display: flex;
    gap:20px;
    padding: 50px;
}
.thes_main_pic_div img{
   
    width: 100%;
}
.thes_main_pic_div{
    width: 150%;
}
.thes_gud{
    display: flex;
    gap:20px;
}
.thes_gud_1 img{
   
   width: 100%;
}
@media only screen and (max-width: 768px) {
    .thesis_pic img {
        height: auto; /* Adjust image height to maintain aspect ratio */
    }

    .thesis_div,
    .thesis_sub_div,
    .thesis_guide_line,
    .thesis_success {
        padding: 20px; /* Adjust padding for smaller screens */
    }

    .thesis_div h2,
    .thesis_sub_div h2,
    .thesis_guide_line h2,
    .thesis_success h2,
    .thesis_guide_line_1 h2 {
        font-size: 20px; 
    }

    .thesis_div p,
    .thesis_sub_div p,
    .thesis_guide_line_1 p,
    .thesis_success p {
        font-size: 15px; /* Decrease font size for smaller screens */
    }
    .thes_pic_div{
        display: grid;
    }
    .thes_gud_1 img{
        width: 100%;
        margin-top: 0%;
    }
    .thes_gud{
        display: grid;
    }
  
    .thesis_succes{
        padding: 30px;
    }
    .thes_pic_div{
        padding: 0px;
    }
   .thes_main_pic_div img{
    width: 100%;
    margin-top: 100px;
   }
}


@media only screen and (max-width: 768px) {
    .thesis-guide_line {
        padding: 20px;
    }

    .thesis-guide_line h2 {
        font-size: 1.5em;
    }

    .thesis-guide_line_1 {
        display: flex;
        flex-direction: column;
    }
    .thes_main_pic_div {
        width: 100%;
        margin-top: -130px;
     }
}


@media only screen and (max-width: 768px) {
    .thesis-guide_line {
        margin-bottom: 10px;
    }

    .thesis-guide_line_1 h2 {
        font-size: 18px;
    }

    .thesis-guide_line_1 p {
        font-size: 15px;
        margin-bottom: 10px;
    }
    .thesis-guide_line{
        width: 88%;
    }
}

.body{
    overflow-x: hidden;
}