.paper_div img{
    width: 100%;
    height: 450px;
}
.research_paper_main_div{
    display: flex;
    gap:20px;
    padding: 50px;
   
}
.pa_pic img{
    width: 80%;
    margin-left: 100px;
}
.pare_main_div h2, .paer_contant_div h2, .pare_strt h2{
    color:#066fb2 ;
    font-size: 20px;
    font-weight: 400;
}
.paper_main_div h2{
    color:#066fb2 ;
    font-size: 20px;
}

.paper_main_div {
    text-align: justify;
    width: 100%;
}
.paper_main_div p{
    line-height: 1.9;
    font-size: 15px;
    text-align: justify;
     font-family: "Poppins", Sans-serif;
}
.pare_main_div p{
    line-height: 1.9;
    font-size: 15px;
    text-align: justify;
     font-family: "Poppins", Sans-serif;
}
.pare_main_div{
   
    padding: 50px;
  margin-top: -90px;
    text-align: justify;
}
.paper_sub_div{
    padding: 50px;
    margin-top: -25px;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    width: 85%;
    margin-left: 50px;
    border-radius: 6px;
}
.paper_sub_div p{
    line-height: 1.9;
    font-size: 15px;
    text-align: justify;
     font-family: "Poppins", Sans-serif;
   
}

.paer_main_div{
    display: flex;
    gap:20px;
    padding: 50px;
}
.paper_div_pic img{
    width: 100%;
   
}
.paer_contant_div li{
    line-height: 1.9;
    font-size: 15px;
    text-align: justify;
     font-family: "Poppins", Sans-serif;
}
.paer_contant_div{
    width: 100%;
}
.paper_structure{
    padding: 50px;
    width: 100%;
}
.pare_strt_1 h2{
    font-size: 15px;
    color: #066fb2;
    font-weight: 400;
}
.pare_strt_1 p{
   
    line-height: 1.9;
    font-size: 15px;
    text-align: justify;
     font-family: "Poppins", Sans-serif;
}
.pare_strt p{
    line-height: 1.9;
    font-size: 15px;
    text-align: justify;
     font-family: "Poppins", Sans-serif;
}



@media (max-width: 767px) {
    .research_paper_main_div {
        padding: 10px; /* Further reduce padding for smaller screens */
        display: grid;
      padding: 15px;
     
    }
    .research_paper_main_div h2{
        text-align: justify;
    }
    .paper_main_div{
        width: 95%;
        text-align: justify;
    }

    .paper_sub_div,
    .paper_structure {
        padding: 20px; /* Further reduce padding for smaller screens */
        width: 90%;
    }
    .paper_main_div p{
        text-align: justify;
    
    }
    
    .pare_main_div{
        margin-top: 0%;
        width: 85%;
        padding: 20px;
       
    }
    .paer_main_div{
        display: grid;
        padding: 30px;
        margin-top: 0%;
    }
    .paper_div img{
        height: auto;
    }
    .pa_pic img{
        width: 100%;
        margin-left: 0px;
    }
}

.re img{
    width: 100%;
    height: 450px;
}

@media (max-width: 767px) {
    .re img {
        height: 200px; /* Reset height to auto for responsive scaling */
    }
   
}

.re_li_v2{
    padding: 50px;
    margin-top: 0%;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    width: 85%;
    margin-left: 50px;
    border-radius: 6px;
}
.re_li_v2 h2{
    color: #066fb2;
    font-size: 20px;
    font-weight: 400
}
.re_li_v2 li{
    font-size: 15px;
    text-align: justify;
     font-family: "Poppins", Sans-serif;
     line-height: 1.9;
}

.re_pi_li img{
    width: 100%;
    margin-top: 100px;
}
.re_pi_li{
    width: 100%;
}

.papa_re{
    display: flex;
}
.fin_rech{
    padding: 50px;
    margin-top: -5%;
}
.fin_rech h2{
    font-size: 20px;
    font-weight: 400;
    color: #066fb2;
}
.fin_rech p{
    text-align: justify;
    font-size: 15px;
    line-height: 1.9;
     font-family: "Poppins", Sans-serif;
}

@media screen and (max-width: 768px) {
    .re_li_v2 {
      padding: 20px;
      margin-top: 0;
      margin-left: 20px;
      border-radius: 6px;
      width: 80%;
    }
  
    .re_li_v2 h2 {
      font-size: 18px;
    }
  
    .re_li_v2 li {
      font-size: 14px;
    }
  
    .re_pi_li img {
      margin-top: 0px;
      margin-left: -10px;
    }
  
    .papa_re {
      display: block;
    }
  
    .fin_rech {
      padding: 30px;
      margin-top: -5%;
    }
  
    .fin_rech h2 {
      font-size: 18px;
    }
  
    .fin_rech p {
      font-size: 14px;
    }
  }
  
  @media screen and (max-width: 768px) {
    .paper_sub_div {
      padding: 20px;
      margin-top: -15px;
      margin-left: 20px;
      border-radius: 6px;
      width: 80%;
    }
  }
  

.body{
    overflow-x: hidden;
}


.whatsapp_float{
    position: fixed;
    bottom: 80px;
    right: 20px;
}
