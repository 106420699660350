.python_main_div img{
    width:100%;
    height: 450px;
}
body{
    overflow-x: hidden;
}
.python_pic img{
    width: 100%;
    margin-top: 0%;
   
}
.python_main_div_la{
    display: flex;
    gap: 20px;
    padding: 50px;
}
.python_div{
    width: 95%;
   
}
.python_div, .py_future_1, .python_cont_div, .python_pic_div, .python_final_div h2{
    font-size: 20px;
    font-weight: 400;
    
}

.python_div p{
    text-align: justify;
    line-height: 1.9;
    font-size: 15px;
 font-family: "Poppins", Sans-serif;
}
.py_futuer{
    display: flex;
    padding: 50px;
    margin-top: -5%;
}
.py_future_1{
    width: 100%;
}
.pyt_con{
    width: 100%;
}
.pyt_con p{
    text-align: justify;
    line-height: 1.9;
    font-size: 15px;
 font-family: "Poppins", Sans-serif;
}
.python_cont_div{
    padding: 50px;
    margin-top: -5%;
}
.python_cont_div ,.python_final_div p{
    text-align: justify;
    line-height: 1.9;
    font-size: 15px;
 font-family: "Poppins", Sans-serif;
}
.py_future_1,.python_card_div  li{
    text-align: justify;
    line-height: 1.9;
    font-size: 15px;
 font-family: "Poppins", Sans-serif;
}
.python_pic_div_1{
    display: flex;
    gap:10px;
}
.python_pic_div{
    padding: 50px;
    margin-top: -5%;
}
 .py_future_1 h2, .python_cont_div h2, .python_pic_div h2{
    font-size: 20px;
    font-weight: 400;
   
}
.python_card_div h2{
    color: #066fb2;
}



.python_card_div{
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    padding: 20px;
    border-radius: 6px;
}
.python_final_div{
    padding: 50px;
}
@media only screen and (max-width: 768px) {
    .python_div,
   
    .python_cont_div,
    .python_final_div,
    .python_pic_div {
        padding: 30px; /* Reduced padding for smaller screens */
    }
    .py_future_1{
        padding: 10px;
    }

    .python_pic_div_1 {
        flex-direction: column;
        gap: 0; /* Remove gap between elements */
    }

    .python_pic_div {
        padding: 20px; /* Reduced padding for smaller screens */
    }
    .python_main_div_la {
        display: grid;
        width: 100%;
        padding: 20px;
        margin-left: -20px;
    }
    .py_futuer{
        display: grid;
        padding: 30px;
    }
    .python_pic_div_1{
        display: grid;
        gap:10px;
    }
    .python_pic img{
        width: 90%;
        padding: 15px;
        margin-top: 0%;
        margin-left: 10px;
    }
    .python_main_div img{
        height: 200px;
    }
    .python_div{
        width: 85%;
    }
}