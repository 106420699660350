.ser_fact {
   background-image: linear-gradient(to right, rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url('../newwebpic//14.jpg');
    background-size: cover;
    background-position: center;
    padding: 20px; 
   
  } 
  .ser_fact_1 p{
    font-size: 45px;
    text-align: center;
    color: #fff;
    font-weight: bold;
  }

  .ser_div_1{
    padding: 50px;
    
  }
 
  
.ser_div_cnt h2{
    text-align: center;
    font-size: 25px;
    color: #066fb2;
}
.ser_div_cnt p{
    text-align: justify;
    font-size: 15px;
    font-family: "Poppins", Sans-serif;
    line-height: 1.9;
}
.fin_ser_div_1{
    padding: 50px;
}
.ser_final_div p{
    text-align: justify;
    font-size: 15px;
    font-family: "Poppins", Sans-serif;
    line-height: 1.9;
}
.ser_final_div{
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
    border-radius: 6px;
    padding: 20px;
  }
  .ser_our_div{
    padding: 50px;
  }
  .ser_our_div_1 h2{
    text-align: center;
    font-size: 30px;
    color: #066fb2;
  }
  .ser_our_div_1 p{
    text-align: justify;
    font-size: 15px;
    font-family: "Poppins", Sans-serif;
    line-height: 1.9;
  }

  @media (max-width: 768px) {
    .ser_fact_1 p {
      font-size: 30px;
    }
  
    .ser_div_1 {
      padding: 30px;
    }
  
    .ser_div_cnt h2 {
      font-size: 20px;
    }
  
    .ser_div_cnt p {
      font-size: 14px;
    }
  
    .fin_ser_div_1 {
      padding: 30px;
    }
  
    .ser_final_div p {
      font-size: 14px;
    }
  
    .ser_our_div {
      padding: 30px;
    }
  
    .ser_our_div_1 h2 {
      font-size: 25px;
    }
  
    .ser_our_div_1 p {
      font-size: 14px;
    }
  }
  body{
    overflow-x: hidden;
}
.ser_btnn {
   text-align: center;
}
.ser_btnn button{
    padding: 15px 30px;
    background-color: #066fb2;
    border-radius: 6px;
    color: #fff;
    text-decoration: none;
}