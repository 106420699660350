.jouranl_main img{
    width: 100%;
    height: 450px;
}

.journal_main_div p{
    text-align: justify;
    line-height: 1.9;
    font-size: 15px;
      font-family: "Poppins", Sans-serif;
   
}
.jouranl_card_main_div{
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    width: 100%;
    height: auto;
padding: 20px;
border-radius: 6px;
}
.journal_card_div{
    display: grid;
    gap:20px;
    padding: 50px;
    margin-top: -5%;
}
.jouranl_card_main_div h2{
   font-size: 18px;
   color:#066fb2;
   font-weight: 400;

}
.journal_main_div h2{
    color: #066fb2;
   
}

.jouranl_card_main_div p{
    text-align: justify;
    line-height: 1.9;
    font-size: 15px;
      font-family: "Poppins", Sans-serif;
}
.jouranl_fianla_div{
    padding: 50px;
    display: flex;
    gap: 20px;
    margin-top: -5%;
}
.final_jouranla_div{
    width: 100%;
}
.final_jouranla_div h2{
    color: #066fb2;
    font-size: 18px;
    width: 100%;
    font-weight: 400;
}
.final_sib_journal_div h2{
    color: #066fb2;
    font-size: 15px;
}
.final_sib_journal_div, .jon_m_div p{
    text-align: justify;
    line-height: 1.9;
    font-size: 15px;
      font-family: "Poppins", Sans-serif;
}
.journal_div{
    display: flex;
    gap:20px;
    padding: 50px;
    margin-top: -5%;
}
.jou_ppic img{
    width: 80%;
    margin-left: 50px;
    
    
}
.jou_ppic{
    width: 100%;
}
.journal_main_div{
    width: 100%;
}
.finbal_pic img{
    width: 100%;
    
}
.finbal_pic{
    width: 100%;
}
@media (max-width: 767px) {
   
    .journal_card_div,
    .journal_div,
    .jouranl_fianla_div {
        padding: 30px; /* Further reduce padding for smaller screens */
    }
    .journal_card_div{
        display: grid;
    padding: 35px;
    margin-left: -20px;
    }
    .jouranl_card_main_div {
        width: 95%;
        
    }
    .journal_div {
        flex-direction: column; /* Stack items vertically on smaller screens */
        gap: 10px; /* Reduce the gap between items */
        padding: 20px; /* Decrease padding for better spacing on smaller screens */
    }

    .finbal_pic img {
        margin-top: 20px; /* Adjust margin-top for better spacing */
    }
    .jouranl_fianla_div{
        display: grid;
    }
    .jouranl_main img{
        height: auto;
    }
    .jou_ppic img{
        width: 100%;
        margin-top: 0%;
        margin-left: 0px;
    }
   
   
}

.jon_m_div{
    padding: 50px;
   
}
.jon_m_div h2{
    color: #066fb2;
}


@media only screen and (max-width: 768px) {
    .jon_m_div {
        padding: 20px;
    }
}


.body{
    overflow-x: hidden;
}