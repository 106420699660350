.company_prin {
  padding: 10px;

  overflow: hidden;
  
}
.cmp_div h2{
  text-align: center;
  color: #fff;
}
.cmp_div p{
  text-align: center;
  color: #fff;
  font-family: "Poppins", Sans-serif;
}
body{
  overflow-x: hidden;
}
.princple_img img{
  width: 85%;
  margin-left: -100px;
 
}
.princple_img {
  float: left; /* Set image to the left */
  margin-right: 20px; /* Adjust margin as needed */
  
}
.company_princples{
  width: 100%;
  padding: 15px;
  /* margin-left: -50px; */
}

.company_princples{
  display: flex;
  
  gap:20px;
}
.company_princples h2{
  color: #ffffff;
  font-family: 'square721_btroman';
  font-weight: 600;
  font-size: 20px;
}.company_princples p{
  line-height: 1.9;
  font-family: "Poppins", Sans-serif;
  font-size: 15px;
  color: #ffffff;
}
/*








/* Add your other styles as needed */

/* .company_princple {
  overflow-y: scroll; 
  height: 500px; 
  scroll-behavior: smooth; 
}


.company_princple {
  animation: slow-motion 5s infinite alternate;
}


@keyframes slow-motion {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(50px); 
  }
} */
/* Animation for left-to-right */

@media only screen and (max-width: 768px) {
  
  
  .imgSec {
    width: 100%; 
  }
}
.MoveLeft {
  animation: leftToRight 2s ease-out; /* Adjust duration as needed */
}

@keyframes leftToRight {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0%);
  }
}

/* Animation for right-to-left */
.MoveRight {
  animation: rightToLeft 1s ease-out;
}

@keyframes rightToLeft {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0%);
  }
}
.MoveRight{
  animation: rightToLeft 2s ease-out;
}

@keyframes topToTop {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0%);
  }
}


.MoveTop {
  animation: topToTop 1s ease-out;
}

.oru_commite h2{
  margin: auto;
  text-align: center;
  margin-top: 50px;
  color: #066fb2;
}
.commite_main_div{
 
  border: 1px solid #eee;
  width: 100%;
  height: 120px;
}
.commete_div{
  display: flex;
 gap:10px;
  padding: 40px;
}
.commite_main_div h2{
  font-size: 20px;
  font-family: calibri;
 padding: 10px;
  font-weight: 600;
}
.commite_main_div img{
  width: 20%;
  height: auto;
  margin-left: 40%;
  margin-top: 5px;
}
.commite_main_div {
 display: grid;
 margin: auto;
 text-align: center;
 background: #ffffff;
}
.commite_main_div_1 {
  display: grid;
  margin: auto;
  text-align: center;

 }
.commite_main_div_1{
 
  border: 1px solid #eee;
  width: 100%;
  height: 125px;
}
.commite_main_div_1 img{
  width: 20%;
  height: auto;
  margin-left: 40%;
  margin-top: 5px;
}
.commite_main_div_1 h2{
  font-size: 20px;
  font-family: calibri;
 padding: 10px;
  font-weight: 600;
  
}
.commete_div_1{
  display: flex;
  gap:10px;
 margin-left: 10%;
 margin-right: 10%;
  margin-top: -40px;
}

.fainal_div_1 h2{
  text-align: center;
  color:#066fb2;
}
.fainal_div_1 p{
  text-align: center;
  padding: 0px;
  margin-top: 20px;
  line-height: 1.5;
      font-family: "Poppins", Sans-serif;
      font-size: 15px;
}

.ocean {
  height: 80px; 
  width: 100%;
  position: absolute;
  margin-top: -6%;
  left: 0;
  right: 0;
  overflow-x: hidden;
}

.wave {
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 800 88.7'%3E%3Cpath d='M800 56.9c-155.5 0-204.9-50-405.5-49.9-200 0-250 49.9-394.5 49.9v31.8h800v-.2-31.6z' fill='%23003F7C'/%3E%3C/svg%3E");
  position: absolute;
  width: 200%;
  height: 100%;
  animation: wave 10s -3s linear infinite;
  transform: translate3d(0, 0, 0);
  opacity: 0.8;
}

.wave:nth-of-type(2) {
  bottom: 0;
  animation: wave 18s linear reverse infinite;
  opacity: 0.5;
}

.wave:nth-of-type(3) {
  bottom: 0;
  animation: wave 20s -1s linear infinite;
  opacity: 0.5;
}

@keyframes wave {
    0% {transform: translateX(0);}
    50% {transform: translateX(-25%);}
    100% {transform: translateX(-50%);}
}

.fainal_div{
  /* background-image: url('../image//vave.avif');  */
  background-size: cover;
  background-position: center;
  padding: 80px;
 
}
.fainal_btn {
  text-align: center;
}
.fainal_btn button{
  padding: 10px 10px;
  border: none;
  border-radius: 5px;
  background-color: blue;
  color: #fff;
}
.clint_1 img{
  width: 20%;
  height: auto;
  margin-left: 10px;
}
.clint {
  display: flex;
  gap:20px;
  padding: 0px;
 margin-top: 30px;
}
.clint_1{
 

  border-radius: 15px;
}
.clint_1{
  display: flex;
  gap:50px;
  /* background: aliceblue; */
}
.clint_div_1{
  display: grid;
}
.clint_div_1 p{
  margin-top: -20px;
}
 /* .back_prin {
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  margin-left: 20px;
  margin-right: 20px;
  border-radius: 6px;
 
}
   */

.clint_1 {
  opacity: 0;
  transform: translateY(-50px); 
  transition: opacity 1s ease, transform 1s ease;
}

.clint_1.animate {
  opacity: 1;
  transform: translateY(0); /* Move div to its original position */
}

.software_sub_contant{
  opacity: 0;
  transform: translateY(-50px); /* Move div 50px up initially */
  transition: opacity 1s ease, transform 1s ease;
}

.software_sub_contant.animate{
  opacity: 1;
  transform: translateY(0);
}


/* 
.company_prin{
  opacity: 0;
  transform: translateY(-50px);
  transition: opacity 1s ease, transform 1s ease;
}
.company_prin.animate{
  opacity: 1;
  transform: translateY(0);
} */



 .back_prin {
  background-image: url('../anime/1.jpg');
  background-size: cover;
  background-position: center;
  padding: 20px; 
} 


.sub_content_imga{
  /* background-image: url('../anime//4.jpg'); */
  background-size: cover;
  background-position: center;
  padding: 20px; 
  
}
/* .testimonial::before {
  content: "";
  position: absolute;
  top: 30%;
  right: 0;
  width: 100px;
 height: 100px;
 
  background: url(../anime//1\ \(3\).png) center center no-repeat;
  animation: TestimonialMoveRight 100s linear infinite;
  z-index: -1;
} */

.sub_contant_1 h2 em{
  color: blue;
 
}
.seasense_cotent_1 em{
  color: blue;
}
.animate1 {
  transition: animation 15s ease-in-out;
  display: inline-block;
}
@keyframes animate1 {
  0% {
    transform: translate(0px);
  }
  100% {
      transform: translate(60px);
  }
}
@keyframes animate2 {
  0% {
    transform: translate(30px);
  }
  100% {
      transform: translate(50px);
  }
}
@keyframes animate3 {
  0%{
      transform: rotate(0deg)
  }
  100%{
      transform: rotate(360deg);
  }
}



.service_div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 50px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 20px;
  background-color: rgba(255, 255, 255, 0.45);
  /* box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.25); */
  backdrop-filter: blur(15px);
  width: 250px;
  margin-left: 30px;
}

.wlcom_search img{
  width: 150%;
  /* margin-left: 30%; */
  border-radius: 8px;
  margin-top: 0px;
}
.dd_phd{
  display: flex;
  gap:20px;
  padding: 20px;
  justify-content: space-between;
}

.wlcm_div_content p{
  text-align: justify;
  line-height: 1.9;
  font-size: 15px;
  font-weight: 400;
  width: 80%;
  font-family: "Poppins", Sans-serif;
}
.welcom_main_div_1 h2{
  font-size: 30px;

 
text-align: center;

}
.remo_btn{
  text-align: center;
 
}

.remo_btn button{
  border: none;
  background-color: #fff;
}






.secondaryText{
  font-weight: bold;
  font-size: 40px;
  text-transform: capitalize;
  transition: all 1.5s ease-in-out;
}
.primaryText{
  color: #673694;
  font-size: 16px;
  font-weight: bold;
  transition: all 1.5s ease-in-out;
}
.imgSec{
  display: flex;
  justify-content: center;
  transition: all 0.5s ease-in-out;
}
.img-out-of-view{
  transform: scale(0);
  opacity: 0;
}
.img-in-view{
  transform: scale(1);
  opacity: 1;
}
.font{
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale;
}
.tableData{
  text-transform: capitalize;
}
.color{
  color: #687076;
}
.text-out-of-view{
  transform: translate3d(0px,-100px,0px);
  opacity: 0;
}
.text-in-view{
  transform: translateZ(0px);
  opacity: 1;
}
.text-container{
  transition-timing-function: cubic-bezier(.25,.25,.75,.75);
  transition-duration: 0.3s;
}
.text-container1{
  transition-timing-function: cubic-bezier(.25,.25,.75,.75);
  transition-duration: 2.5s;
}
.description-out-of-view{
  transform: scale(0);
  opacity: 0;
}
.description-in-view{
  transform: scale(1);
  opacity: 1;
}
.icon-container{
  position: absolute;
  right: 0px;
  opacity: 0.1;
  height: 100% !important;
  width: 35% !important;
  text-align: end !important;
}
.animate1 {
  transition: animation 15s ease-in-out;
  display: inline-block;
}
@keyframes animate1 {
  0% {
    transform: translate(0px);
  }
  100% {
      transform: translate(60px);
  }
}
@keyframes animate2 {
  0% {
    transform: translate(30px);
  }
  100% {
      transform: translate(150px);
  }
}
@keyframes animate3 {
  0%{
      transform: rotate(0deg)
  }
  100%{
      transform: rotate(360deg);
  }
}
.div-line{
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  position: absolute;
  top: 0;
  height: 111%;
  width: 100%;
}
.div-lines{
  width: 1px;
  height: 100%;
  background: #fff;
  opacity: .1;
}
.round{
  position: absolute;
  width: 100px;
  height: 100px;
  background-repeat: no-repeat;
  background-size: contain;
  bottom: 5%;
  left: 25%;
  opacity: .5;
  display: flex;
  align-items: center;
}
.round1{
  display: flex;
  align-items: center;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  bottom:50%;
  left:5%;
   width:80px;
   height:80px;
   opacity:.3
}

.right{
  transform: rotate(-90deg);
}
.bannerText-out-of-view{
  transform: translateX(100px);
  opacity: 0;
}
.bannerText-in-view{
  transform: translateX(0px);
  opacity: 1;
}

@media only screen and (max-width: 768px) {
  .right{
    transform: rotate(-0deg);
    opacity: 2;
  }


}


.exper_main_div {
  background-image: url('../anime/1.jpg');
  background-size: cover;
  background-position: center;
  padding: 20px; 
} 
.exper_div{
  text-align: center;
}
.exper_div h2{
  color: #fff;
  font-size: 35px;
}
.exper_div p{
  color: #fff;
  font-family: 'Courier New', Courier, monospace;
}
.exper_num button{
  padding: 15px 30px;
  border: none;
  border-radius: 5px;
  background: #066fb2;
  color: #fff;
  font-size: 15px;
  letter-spacing: 1px;
}
.sub_co_div img{
  width: 80%;
}
.sub_co{
  display: flex;
  gap:20px;

  padding: 20px;
}
.sy_pre h2{
  text-align: center;
  color: #066fb2;
}
.sub_co_cont_div{
  width: 90%;
  padding: 10px;
  text-align: justify;
}
.sub_co_cont_div h2{
  color: #066fb2;
}
.sub_co_cont_div p{
  line-height: 1.9;
  font-family: "Poppins", Sans-serif;
  font-size: 15px;
  font-weight: 400;
  text-align: justify;
}

.sub_co_bt_div button{
padding: 15px 30px;
border-radius: 6px;
border: none;
color: #fff;
background-color: #066fb2;
cursor: pointer;
}
.wlc_btn_div{
  text-align: center;
}
.wlc_btn_div button{
  padding: 15px 30px;
  border-radius: 5px;
  border: none;
  background-color: royalblue;
  color: #fff;
}
.pic_sib_pic_div img{
  width: 100%;
  
}
.pic_main_div h2{
  text-align: center;
  font-size: 30px;
  color: #066fb2;

}
.best_sub_div p{
  font-size: 15px;
  font-weight: 400;
  text-align: justify;
  font-family: "Poppins", Sans-serif;
  color: black;
  line-height: 1.9;

}

.best_sub_div h2{
  text-align: center;
 
    font-size: 30px;
    color: #066fb2;
   
}
.best_main_div {
  padding: 20px;
}
.pro_main_div {
  background-image:linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../newwebpic//14.jpg');
  background-size: cover;
  background-position: center;
  padding: 20px; 
} 
.pro_sub_div h2{
  font-family: 'Volkhov', serif;
  color: #FFFFFF;
  text-align: center;
}
.pro_sub_div p{
  text-align: center;
  font-size: 15px;
    line-height: 1.5;
    color: #ffffff;
    font-family: "Poppins", Sans-serif;
}
.pro_btn {
  text-align: center;
}
.pro_btn button{
  padding: 15px 30px;
  border: 1px solid blue;
  border-radius: 5px;
  background-color: transparent;
  color: white;

}




/* Apply animation to button */

.advance_code_div{
  text-align: center;

}
.advnce_div_cod h2{
font-size: 30px;
    color: #0e314c;
}
.advnce_div_cod p{
font-size: 15px;
    font-weight: 400;
    text-align: justify;
    font-family: "Poppins", Sans-serif;
    margin-top: 8%;
    line-height: 1.9;
   
}
.advance_code_div{
  display: flex;
  gap:20px;
  padding: 20px;
}
.advnce_pic img{
  width: 80%;
  border-radius: 6px;
 
}
.advnce_pic{
 
 
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  }
.advance_btn button{
  padding: 15px 30px;
  border-radius: 6px;
  border: none;
  color: #fff;
  background-color:#066fb2 ;
  cursor: pointer;
}
.advance_code_div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.advnce_div_cod {
  flex: 1;
}
@keyframes slideInRight {
  0% {
      transform: translateX(100%);
      opacity: 0;
  }
  100% {
      transform: translateX(0%);
      opacity: 1;
  }
}
.animation_container {
  position: relative;
  animation: slideInLeft 1s ease forwards; /* Apply animation to the container */
}

/* Example animation */
@keyframes slideInLeft {
  0% {
      transform: translateX(-100%);
      opacity: 0;
  }
  100% {
      transform: translateX(0%);
      opacity: 1;
  }
}
.animation_container {
  position: relative;
  opacity: 0; /* Start with opacity 0 */
  transition: opacity 0.5s; /* Smooth transition */
}

.animation_container.animate {
  opacity: 1; /* When the animate class is added, change opacity to 1 */
  /* Add your animation here */
}

.tite_card{
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  width: 50%;
  height:300px;
  padding: 20px;
  border-radius: 6px;
}
.tite_card button{
  border: none;
  color: black;
  background-color: #fff;
  
}
.tite_card h3{
  color: black;
}

.tite_card_main_div{
  padding: 50px;
  display: flex;
  gap:50px;
}


@media only screen and (max-width: 768px) {
  .tite_card {
    width: 90%; /* Make the cards full width on smaller screens */
    margin-bottom: 20px; /* Add space between the cards */
  }
  
  .tite_card_main_div {
    padding: 20px; 
    flex-direction: column; 
    align-items: center;
  }
  .company_princples{
    display: grid;
  }
}

.fun_vis{
  padding: 50px;
}
.fun_im img{
  width: 100%;
border-radius: 6px;
}
.fun_vis{
  display: flex;
  gap:30px
}
.vis_co_li h2{
  text-align: center;
  font-size: 35px;
}
.vis_co_li p{
  text-align: center;
  line-height: 1.9;
  font-size: 15px;
  font-family: "Poppins", Sans-serif;
}


.vis_co_li{
  width: 50%;
}

.vi_mul_div{
  display: flex;
  gap: 80px;
  margin-left: 50px;
}
.vi_mul_div_1{
  display: grid;
}
.vi_mul_div_1 h2{
  font-size: 30px;
  font-weight: 400;
}
.star_ic img{
  width: 80px;
  margin-left: 10px;
}


@media screen and (max-width: 768px) {
  .fun_vis {
    padding: 20px; /* Adjust padding for smaller screens */
    display: grid;
  }

.vis_co_li {
  display: grid;
}

  .vi_mul_div {
    flex-direction: row; /* Stack elements vertically on smaller screens */
    gap: 25px; /* Adjust gap between elements */
    margin-left: 0; /* Remove left margin */
  }

  .vi_mul_div_1 {
    justify-items: center; /* Center grid items horizontally */
  }
  .vis_co_li h2{
    font-size: 25px;
  }
 
}
.fun_fact_baclk{
  background-color: #f8faff;
  margin-top: 50px;
}

.fun_fact_main{
  background-image: url('../newwebpic//pattern.png'); 
  background-size: cover;
  background-position: center;
  padding: 100px 0 130px 0;

}
.fun_fact_1 h2{
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 700;
  text-align: center;
}
.fun_fact_1 h1{
  text-align: center;
  font-size: 35px;
    font-weight: 700;
    padding: 0px 0 0px 0;
}
.fact_count{
  display: flex;
  gap: 80px;
  margin-left: 200px;
}
.fact_count_1 h1{
  text-align: center;
  font-size: 70px;
  font-weight: 300;
}
.fact_count_1 h2{
  font-size: 15px;
  font-weight: lighter;
  margin-top: -40px;
}

@media screen and (max-width: 768px) {
  .fun_fact_main {
    padding: 50px 0; /* Adjust padding for smaller screens */
  }

  .fun_fact_1 h1 {
    font-size: 28px; /* Decrease font size for smaller screens */
    padding: 10px;
  }

  .fact_count {
    flex-direction: column; /* Stack elements vertically on smaller screens */
    gap: 0px; /* Adjust gap between elements */
    margin-left: 0; /* Remove left margin */
    text-align: center; /* Center align items */
  }

  .fact_count_1 h1 {
    font-size: 50px; /* Decrease font size for smaller screens */
  }

  .fact_count_1 h2 {
    margin-top: 0; /* Remove negative margin top */
  }
.fun_fact_main {
  padding: 60px 0px;
}
}

.call_fact_div{
margin-top: 25px;
}
.call_fact_div_1 h2{
  text-align: center;
  font-size: 25px;
  font-weight: normal;
}

@media screen and (max-width: 768px) {
  .call_fact_div {
    margin-top: 15px; /* Adjust margin top for smaller screens */
    padding: 20px;
  }

  .call_fact_div_1 h2 {
    font-size: 20px; /* Decrease font size for smaller screens */
  }
}



.fac_ser_main_div{
  padding: 50px;
}
.fac_ser_main_div_1{
  display: flex;
  gap:50px;
}

.fac_pic_main_div img{
  width: 100%;
  border-radius: 4px;
  opacity: 0.9;
}
.fac_main_div_pic{
  display: flex;
  gap: 10px;
  border: 1px solid #eaeaea;
  border-radius: 0 4px 4px 0;
  border-left: none;
height: 100px;

}
.fac_main_div_conmts h2{
  text-align: justify;
  font-size: 20px;
  margin-top: 10px;
}
.fac_main_div_conmts{
  width: 250%;
 
}
.fac_main_div_conmts p{
  font-size: 15px;
}
.fac_div_la_ve h2{
  text-align: center;
  font-size: 38px;
    font-weight: 900;
    color: #232323;
}

@media screen and (max-width: 768px) {
  .fac_ser_main_div_1 {
    display: grid;
    gap:10px;
  }
  
  .fac_main_div_conmts h2{
    font-size: 15px;
  }
  .fac_main_div_conmts p{
    font-size: 12px;
  }
  .fac_div_la_ve h2 {
    font-size: 20px;
    font-weight: 900;
    color: #232323;
    text-align: center;
  }
  .fac_ser_main_div{
    padding: 20px;
  }
  .fac_main_div_pic{
    height: 95%;
  }
}