
.seasense_head {
    position: absolute;
    top: 20%; /* Adjust top position as needed */
    left: 20%; /* Adjust left position as needed */
    z-index: 2; /* Set a higher z-index to bring it in front of the background */
    display: flex;
    align-items: center;
    padding: 20px;
  }

.seasense_menus {
    display: flex;
    gap: 20px;
    /* gap:30px;
    margin-left: auto; */
}
.seasense_menus label h2{
    font-size: 15px;
    padding-top: 20px;
    color: #fff;
}
.seasense_menus label {
    margin-right: 20px;
  }
  .seasense_menus label:hover {
    color: blue;
  }
.seasense_index_head {
    position: relative;
  }

  .seasense_sub_head {
    position: relative;
   
  }
 

  .animated-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
 
    animation: animateBackground 10s linear infinite;
}
.back_head_img {
    position: relative;
    width: 100%;
  height: 100%;
}

.back_head_img {
    position: relative;
}

.background-image {
    width: 100%;
    height: 550px;
    display: block;
    background: rgba(0, 0, 0, .6);
}

.background-image-container {
  position: relative;
  width: 100%;
  height: 550px;
}

.background-image {
  width: 100%;
  height: 100%;
}

.background-image-container::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}



@keyframes slideIn {
    0% {
        opacity: 0;
        transform: translateX(-50px); /* Start position */
    }
    100% {
        opacity: 1;
        transform: translateX(0); /* End position */
    }
}

/* .menu-icon {
    position: absolute;
    top: -5%; 
    left: 220%; 
    cursor: pointer;
    z-index: 1000; 
  } */
  
  .bar {
    width: 25px;
    height: 2px;
    background-color: #bff6fe; /* Color of the bars */
    margin: 6px 0;
    transition: 0.4s;
  }
  
/* 
  .menu-icon:hover .bar:first-child {
    transform: rotate(-45deg) translate(-5px, 6px);
  }
  
  .menu-icon:hover .bar:nth-child(2) {
    opacity: 0;
  }
  
  .menu-icon:hover .bar:last-child {
    transform: rotate(45deg) translate(-5px, -6px);
  }
   */
  .menu-container {
    position: fixed;
    top: 0;
    right: -250px; /* Initially hide the menu off-screen */
    width: 250px;
    height: 100%;
    background-color: #333;
    transition: right 0.5s ease; /* Add transition for smooth animation */
  }
  
  .menu-container ul {
    list-style: none;
    padding: 0;
    color: white;
  }
  
  .menu-container ul li {
    padding: 15px;
  }
  
  .menu-open {
    right: 0; /* Show the menu by sliding it from the right */
  }

  


.logo {
    position: absolute;
    top: 75%; /* Adjust this value as needed */
    left: 22%; /* Adjust this value as needed */
    z-index: 999; /* Ensure the logo appears on top */
}
.content-overlay img{
    max-width: 55%;
    padding: 45px;
    margin-left: -160px;
}

.content_sub{
    position: absolute;
    top: 80%;
    left: 35%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: white;
}
.content_sub p{
    text-align: justify;
    font-family: "Poppins", Sans-serif;

    font-size: 15px;
    line-height: 1.5;
   width: 45%;
    opacity: 0; /* Initially hide the element */
    transform: translateX(50px); /* Move the element to the right */
    animation: slideIn 4s ease forwards;
     margin-top: -100px; 
}

@keyframes slideIn {
    0% {
        opacity: 0;
        transform: translateX(50px); /* Start position */
    }
    100% {
        opacity: 1;
        transform: translateX(0); /* End position */
    }
}
.content-overlay p {
    opacity: 0; /* Initially hide the element */
    transform: translateY(-50px); /* Move the element up */
    animation: fadeInUp 1.5s ease forwards; /* Define the animation */
    font-family: "Poppins", Sans-serif;
    font-size: 15px;

    
}

@keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translateY(-50px); /* Start position */
    }
    100% {
        opacity: 1;
        transform: translateY(0); /* End position */
    }
}

 

 
  .seasense_cotent_1{
    text-align: center;
  }
.seasense_cotent_1 h2{
  font-size: 30px;
  color: #066fb2;
}
  .contact_div_1{
   box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,;
    width: 25%;
    height: 220px;
   margin: auto;
   background-color: #ffffff;
   border: none;
   border-radius: 5px;
  }
  .contact_div{
    display: flex;
    gap:20px
  }
  .contact_div_1{
    padding: 30px;
  }
  .contact_div_1 h2{
    font-size: 20px;
    font-family: calibri;
    font-weight: 800;
    color: royalblue;
  }
  .contact_div_1 p{
   color: #767373;
   line-height: 1.9;
   font-family: "Poppins", Sans-serif;
   
  }
  .con{
    /* background-color:  #eff2ff; */
    /* #eff2ff */
    height: 400px;
    padding: 40px;
  }
  .contact_div_1 img{
    width: 20%;
    height: auto;
  }

  .contact_div {
    display: flex;
    justify-content: space-around;
    opacity: 0;
    transform: translateY(20px);
    
}





.rotate-360 {
    transition: transform 0.5s ease-in-out;
}

.rotate-360:hover {
    transform: rotate(360deg);
}





.contact_div_1 .new-content button{
    border: none;
    background: transparent;
    cursor: pointer;
    color: #ffffff;
}
.contact_div_1 .new-content h3{
    font-size: 15px;
    color: blcak;
}


.sub_contant_1{
    text-align: center;
}
.sub_contant_1 h2{
  color: #066fb2;
  font-size:30px;
}
.sub_contant_1 p{
  color: #066fb2;
}
.service_main_div {
    display: flex;
    gap: 20px;
    /* overflow-y: auto;
    max-height: 300px; 
     */
     /* margin-left: 10px; */
     margin: auto;
}

.service_div {
     box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    width: 45%;
    /* margin-right: 0px;
    opacity: 0;
    animation: slideIn 6s linear infinite; */
    height: auto;
    padding: 15px;
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    
}
.service_btn {
  perspective: 1000px; /* Adds perspective for 3D effect */
  text-align: center;
}

.animated-button {
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  transition: transform 0.3s ease;
}

.animated-button:hover {
  transform: rotateX(30deg); /* Rotates the button along the x-axis */
}




.service_div:hover {
  cursor: default; /* Removes the cursor when hovering over the div */
}

.service_div h2 {
    color: royalblue;
    font-size: 15px;
    text-align: center;
}
.service_div p{
    font-size: 14px;
    text-align: left;
    line-height: 1.9;
    font-family: "Poppins", Sans-serif;
    font-weight: 300;
    text-align: center;
}



.project_service_1{
text-align: center;
}
.project_service_1 h2{
    font-size: 45px;
}
.service_div img {
    width: 30px;
    height: auto;
    margin-left: 45%;
}

  .card {
    width: 100%;
    height: 40px;
    border-radius: 20px;
    background: #f5f5f5;
    position: relative;
    padding: 1.8rem;
    border: 2px solid #c3c6ce;
    transition: 0.5s ease-out;
    overflow: visible;
   }
   
   
   
   .card-button {
    transform: translate(-50%, 125%);
    width: 60%;
    border-radius: 1rem;
    border: none;
    background-color: #008bf8;
    color: #fff;
    font-size: 1rem;
    padding: .5rem 1rem;
    position: absolute;
    left: 50%;
    bottom: 0;
    opacity: 0;
    transition: 0.3s ease-out;
   }

   .text-body {
    color: rgb(134, 134, 134);
   }
   
   /*Text*/
   .text-title {
    /* font-size: 1.5em; */
    font-weight: bold;
   }
   
   /*Hover*/
   .card:hover {
    border-color: #008bf8;
    box-shadow: 0 4px 18px 0 rgba(0, 0, 0, 0.25);
   }
   
   .card:hover .card-button {
    transform: translate(-50%, 50%);
    opacity: 1;
   }
   .Car_div{
    display: flex;
    gap:20px;
   }

            
   .btn-grad {
    background-image: linear-gradient(to right, #77A1D3 0%, #79CBCA  51%, #77A1D3  100%);
    margin: 10px;
    padding: 15px 45px;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;            
    box-shadow: 0 0 20px #eee;
    border-radius: 10px;
    display: block;
  }

  .btn-grad:hover {
    background-position: right center; 
    color: #fff;
    text-decoration: none;
  }
 
  .software_content {
    padding: 10px;
    display: flex;
    gap: 20px;
    padding-top: 50px;
  }
  

  .software_content h2 {
    font-size: 45px;
    color: royalblue;
  }
  
  .software_sub_contant {
    padding: 10px;
    overflow: hidden; 
  }
  
  .software_sub_contant p {
    text-align: justify;
    font-size: 14px;
   
  }
  
  /* .animated-heading_sub {
    opacity: 0; 
    animation: slideFromTop 5s ease-in-out forwards;
  }
  
  @keyframes slideFromTop {
    0% {
      opacity: 0;
      transform: translateY(-100%);
    }
    100% {
      opacity: 1;
      transform: translateY(0%);
    }
  } */
  
  .software_research_img img {
    width: 100%;
    height: auto;
    border-radius: 10px;
  }
  
  /* .animated-heading {
    opacity: 0; 
    animation: slideFromRight 5s ease-in-out forwards; 
  }
  
  @keyframes slideFromRight {
    0% {
      opacity: 0;
      transform: translateX(100%);
    }
    100% {
      opacity: 1;
      transform: translateX(0); 
    }
  }
   */
  .software_research_img {
    overflow: hidden;
  }
  
  .animated-img {
    opacity: 0; 
    animation: slideFromLeft 5s ease-in-out forwards;
  }
  
  @keyframes slideFromLeft {
    0% {
      opacity: 0;
      transform: translateX(-100%); 
    }
    100% {
      opacity: 1;
      transform: translateX(0); 
    }
  }
  
  .animate1 {
    transition: animation 15s ease-in-out;
    display: inline-block;
  }
  @keyframes animate1 {
    0% {
      transform: translate(0px);
    }
    100% {
        transform: translate(60px);
    }
  }
  @keyframes animate2 {
    0% {
      transform: translate(30px);
    }
    100% {
        transform: translate(150px);
    }
  }
  @keyframes animate3 {
    0%{
        transform: rotate(0deg)
    }
    100%{
        transform: rotate(360deg);
    }
  }
  


  .content-overlays {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: white; /* adjust text color as needed */
  }
  .content-overlays h1{
    font-size: 37px;
  }
  .content-overlays p{
    text-align: center;
    font-size: 15px;

  }

  @media (max-width: 768px) {
    .content-overlays {
      width: 90%;
      /* margin-top: 35px; */
    }
    
    .con_di {
      margin-top: 10px;
    }
    .con_di p{
      text-align: justify;
    }

    .hed_lo_di button{
      margin-top: -25px !important;
    }
    .content-overlays{
      margin-top: -15%;
    }
  }


  .con_di p{
    text-align: center;
    line-height: 1.9;
    font-size: 15px;
    
}
.wrapper{
position: absolute;
width: 100%;
height: 100%;
overflow: hidden;
}
.box div{
  position: absolute;
  width: 60px;
  height: 60px;
  background-color: transparent;
  border: 6px solid rgba(255,255,255,0.8);
}
.box div:nth-child(1){
  top:20%;
  left:50%;
  animation: animate 10s linear infinite;
}
.box div:nth-child(2){
  top:70%;
  left:80%;
  animation: animate 7s linear infinite;
}
.box div:nth-child(3){
  top:50%;
  left:90%;
  animation: animate 7s linear infinite;
}
.box div:nth-child(4){
  top:50%;
  left:60%;
  animation: animate 7s linear infinite;
}
.box div:nth-child(5){
  top:6%;
  left:90%;
  animation: animate 7s linear infinite;
}
.box div:nth-child(6){
  top:80%;
  left:40%;
  animation: animate 7s linear infinite;
}
.box div:nth-child(7){
  top:90%;
  left:50%;
  animation: animate 7s linear infinite;
}
.box div:nth-child(8){
  top:60%;
  left:20%;
  animation: animate 7s linear infinite;
}
.box div:nth-child(9){
  top:30%;
  left:80%;
  animation: animate 7s linear infinite;
}
.box div:nth-child(10){
  top:50%;
  left:50%;
  animation: animate 7s linear infinite;
}

.box div:nth-child(11){
  top:40%;
  left:20%;
  animation: animate 7s linear infinite;
}
.box div:nth-child(12){
  top:90%;
  left:20%;
  animation: animate 7s linear infinite;
}
.box div:nth-child(13){
  top:60%;
  left:30%;
  animation: animate 7s linear infinite;
}
.box div:nth-child(14){
  top:40%;
  left:10%;
  animation: animate 7s linear infinite;
}
.box div:nth-child(15){
  top:80%;
  left:80%;
  animation: animate 7s linear infinite;
}
@keyframes animate{
  0%{
    transform: scale(0) translateY(0) rotate(0);
    opacity: 1;
  }
  100%{
    transform: scale(1.3) translateY(-90px) rotate(360deg);
    opacity: 0;
  }
}


.hed_lo_di button{
  padding: 15px 30px;
  background-color: transparent;
  border: 2px solid #fff;
  margin-top: 50px;
  cursor: pointer;

}

.body{
  overflow-x: hidden;
}