.services_pic img{
    width: 100%;
    height: 450px;
}
.service_cont {
    display: flex;
    gap:20px;
    padding: 20px;
}
.service_sub_cont p{
    width: 150%;
    font-family: "Poppins", Sans-serif;

    font-size: 15px;
    font-weight: 400;
    line-height: 1.5;
}
.service_sub_cont h2{
    width: 190%;
    color: royalblue;
    font-family: "Poppins", Sans-serif;

    font-size: 20px;
}
.ser_pic img {
    width: 50%;
    margin-left: 40%;
}
.service_sub_div {
    display: flex;
    gap: 20px;
    /* margin-left: 50px; */
    /* box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px; */
    padding: 22px;
  
}
.service_sub_div button{
    font-size: 15px;
    border: none;
    background-color: #fff;
}
.sub_div_card{
    display: flex;
    gap:20px;
}
.sub_service_div{
   
    padding: 20px;
  
}
.main_service_card{
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    gap:20px;
    display: flex;
    margin-top: 20px;
}
.main_service_sub_div_1 {
    display: flex;
gap:20px;
}
.main_service_card{
    padding: 10px;
    width: 30%;
    border-radius: 5px;
}
.main_service_card h2{
    font-size: 15px;
}
.main_service_card img{
    width: 20%;
}
.main_service_card button{
    width: 50%;
    border: none;
    background-color: #fff;
}
.development_main_div {
   padding: 20px;
}
.develpoment_div{
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    display: flex;
    gap:20px;
    width: 30%;
    padding: 10px;
    border-radius: 6px;
}
.develpoment_div h2{
font-size: 14px;
}
.develpom_div {
    display: flex;
    gap: 20px;
}
.develpoment_div img {
    width: 20%;
}
.develpoment_div button{
    width: 50%;
    border: none;
    background-color: #fff;
}
.develpom_div{
    margin-top: 20px;
}
.add_no{
    padding: 20px;
}
.add_no_main_div{
    display: flex;
    gap:20px;
}
.main_add_div{
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    display: flex;
    gap:20px;
    width: 30%;
    padding: 10px;
    border-radius: 6px;
}
.main_add_div h2{
    font-size: 15px;
}
.main_add_div button{
    width: 50%;
    border: none;
    background-color: #fff;
}
.main_add_div img {
    width: 20%;
}
.main_add_div{
    margin-top: 20px;
}
.appliction{
    padding: 20px;
}
.application_main_div{
    display: flex;
    gap:20px;
}
.appliction_content_div{
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    display: flex;
    gap:20px;
    width: 30%;
    padding: 10px;
    border-radius: 6px;
}
.appliction_content_div h2{
font-size: 15px;
}
.appliction_content_div button{
    width: 50%;
    border:none;
    background-color: #fff;
}
.appliction_content_div img{
    width: 20%;
}


@media (max-width: 768px) {
    .service_cont {
        flex-direction: column;
        padding: 10px;
    }

    .service_sub_cont p,
    .service_sub_cont h2 {
        width: 100%;
    }

    .ser_pic img {
        width: 100%;
        margin-left: 0;
    }

    .service_sub_div {
        flex-direction: column;
        padding: 10px;
    }

    .service_sub_div button {
        width: 100%;
        font-size: 14px;
    }

    .sub_div_card {
        flex-direction: column;
        gap: 10px;
    }

    .main_service_card {
        width: 100%;
        padding: 5px;
    }

    .main_service_sub_div_1 {
        flex-direction: column;
        gap: 10px;
    }

    .main_service_card h2 {
        font-size: 14px;
    }

    .main_service_card img {
        width: 20%;
        padding: 5px;
    }

    .main_service_card button {
        width: 100%;
    }

    .development_main_div,
    .add_no,
    .appliction {
        padding: 10px;
    }

.develpom_div{
    flex-direction: column;
    gap: 10px;
}
  .develpoment_div{
    width: 100%;
    padding: 0px;
  }
  .develpoment_div h2{
    font-size: 14px;
  }
  .develpoment_div img{
    width: 30%;
  }
  .develpoment_div button{
    width: 100%;
  }
  .add_no_main_div{
    flex-direction: column;
    gap: 10px;
  }
  .main_add_div{
    width: 100%;
    padding: 0px;
  }
  .main_add_div h2{
    font-size: 14px;
  }
  .main_add_div img{
    width: 30%;
  }
  .main_add_div button{
    width: 100%;
  }
  .application_main_div{
    flex-direction: column;
    gap: 10px;
  }
  .appliction_content_div{
    width: 100%;
    padding: 0px;
  }
  .appliction_content_div h2{
    font-size: 14px;
  }
  .appliction_content_div img{
    width: 30%;
  }
  .appliction_content_div button{
    width: 100%;
  }
    }


    .service_sub_cont.animate,
    .ser_pic.animate,
    .sub_div_card.animate,
    .develpom_div.animate,
    .add_no_main_div.animate,
    .application_main_div.animate {
        opacity: 1;
        transform: translateY(0);
    }
    .service_sub_cont,
    .ser_pic,
    .sub_div_card,
    .develpom_div,
    .add_no_main_div,
    .application_main_div {
        opacity: 0;
        transform: translateY(-50px);
        transition: opacity 1s ease, transform 1s ease;
    }