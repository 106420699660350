.proof_main_div_1{
    width: 100%;
}
.proof_main_div_1, .proof_sub_content_div,.proof_final_div, .pro_div_li_new p{
    text-align: justify;
    line-height: 1.9;
    font-size: 15px;
  font-family: "Poppins", Sans-serif;
}

.proof_main_div{
    padding: 50px;
    margin-top: -5%;
}
.proof_main_div{
    display: flex;
    gap:20px;
}
.proof_pic img{
    width: 100%;
  
}
.proff_div{
    padding: 50px;
    margin-top: -5%;
}
.proof_main_card_div{
    display: grid;
    gap:10px;
    padding: 50px;
    margin-top: -5%;
}
.proof_sub_card{
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    padding: 20px;
    border-radius: 6px;
}
.proof_sub_card li{
    text-align: justify;
    line-height: 1.9;
    font-size: 15px;
  font-family: "Poppins", Sans-serif;
}
.proof_sub_card h2{
    font-size: 20px;
    font-weight: 400;
    color: #066fb2;
}
.proof_final_div{
    padding: 50px;
    margin-top: -5%;
}
.proff_div{
    display: flex;
    gap:20px;
}
.proof_sub_content_div{
    width: 100%;
}
.proof_pic_2 img{
    width: 100%;
   
}
@media only screen and (max-width: 768px) {
    .proof_main_div,
    .proff_div,
    .proof_final_div {
        padding: 20px; /* Reduced padding for smaller screens */
    }

    .proof_main_div {
        display: flex;
        flex-direction: column; /* Change to column layout for smaller screens */
        gap: 10px; /* Adjusted gap for smaller screens */
    }

    .proof_main_div_1 {
        width: 100%; /* Adjusted width for full width on smaller screens */
    }

    .proof_pic img {
        margin-top: 0%; /* Adjusted margin-top for smaller screens */
    }


    .proof_main_card_div,
    .proof_final_div {
        padding: 30px; /* Reduced padding for smaller screens */
        margin-top: -5%; /* Adjusted margin-top for smaller screens */
        display: grid;
    }

    .proof_sub_card {
        padding: 10px;
        border-radius: 6px;
    }

    .proof_final_div li,
    .proof_sub_card li {
        font-size: 16px; /* Decreased font size for smaller screens */
    }
    .proff_div{
        display:grid ;
    }
    .proof_pic_2 img {
        width: 100%;
        margin-top: -5%;
    }
    .proof_sub_content_div{
        padding: 0px;
    }
    .proof_din_oic img{
        height: 200px !important;
    }
   
}


.proof_din_oic img{
    width: 100%;
    height: 450px;
}
.pro_div_li_new{
    padding: 50px;
}

@media only screen and (max-width: 768px) {
    .pro_div_li_new {
        padding: 20px;
        border-radius: 0;
    }

    .pro_div_li_new h2 {
        font-size: 1.5rem; /* Adjust font size for smaller screens */
        margin-bottom: 10px;
    }

    .pro_div_li_new p {
        margin-bottom: 10px;
    }
}

.body{
    overflow-x: hidden;
}