table {
  width: 100%;
  border-collapse: collapse;
 
}

th, td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
  
}
tr{
    padding: 50px;
}
.table-container{
  padding: 20px;
}
.ad_det{
  padding: 10px;
  margin-top: 15px;
}

.popup-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup {
  background-color: white;
  height: 30%;
  border-radius: 5px;
}

.popup h3 {
  padding: 20px;
  margin-bottom: 10px;
  font-size: 20px;
  text-align: center;
  
}

.popup-buttons button {
  margin-left: 60px;
 
  
}


@media screen and (max-width: 600px) {
    table {
      width: 100%;
      overflow: scroll;
    }
    .table-container {
      overflow-x: auto;
    }
    
    table {
      width: 100%;
      border-collapse: collapse;
    }
    
    th, td {
      border: 1px solid #ddd;
      padding: 8px;
      text-align: left;
    }
    .ad_det{
      padding: 10px;
    }
    @media (max-width: 768px) {
      .popup {
        padding: 10px; 
        width: 90%;
        height: 140px;
      }
    
      .popup-buttons button {
        margin: 5px; 
      }
      .popup h3{
        font-size: 15px;
        text-align: center;
      }
      .popup-buttons{
        margin-left: 85px;
      }
    }
  }