.vlsi_sub_div img{
    width: 100%;
  margin-top: 5%;
 
}
.vlsi_main_div{
    display: flex;
    padding: 50px;
    gap:20px;
}
.vlsi_cont{
    width: 100%;
}
.vlsi_sub_cont_div{
    padding: 50px;
    margin-top: -5%;
}
.vlsi_sub_cont_div, .vlsi_cont, .vlsi_design_1, .vlsi_final_div p{
    text-align: justify;
    line-height: 1.9;
    font-size: 15px;
     font-family: "Poppins", Sans-serif;
}
 .vlsi_design_1 h2{
    font-size: 20px;
    font-weight: 400
}
.vlsi_design_1{
    padding: 50px;
    margin-top: -5%;
}
.vlsi_design_1 li{
    text-align: justify;
    line-height: 1.9;
    font-size: 15px;
     font-family: "Poppins", Sans-serif;
}
.vlsi_design_pic img {
    width: 100%;
    /* padding: 50px; */
   
}
.vlsi_design{
    display: flex;
    gap:10px;
}
.vlsi_design_1{
    width: 100%;
}
.vlsi_final_div{
    padding: 50px;
    margin-top: -5%;
}

@media only screen and (max-width: 768px) {
    .vlsi_main_div {
        padding: 20px; /* Reduced padding for smaller screens */
        display: grid;
    }

    .vlsi_design {
        flex-direction: column;
        gap: 10px;
    }

    .vlsi_design_1 {
        width: auto;
    }

    .vlsi_sub_cont_div,
    .vlsi_design_1,
    .vlsi_final_div {
        padding: 20px; /* Adjust padding for smaller screens */
        margin-top: -5%;
    }

    .vlsi_sub_div img {
        margin-top: 10%; /* Adjusted margin-top for smaller screens */
    }
    .vlsi_back_pic img{
        height: 200px !important;
    }
    .vlsi_design_pic img{
        width: 95%;
        margin-left: 10px;
    }
   
}
.vlsi_back_pic img{
    width: 100%;
    height: 450px;
}

.body{
    overflow-x: hidden;
}