.conatct_sub_div img{
    width: 100%;
    height: 450px;
}
.conts{
    text-align: center;
    font-size: 30px;
}
.conts h2{
    color: #066fb2;
}
.contact_div_1_main{
    display: flex;
    gap:30px;
    padding: 25px;
}
.conatct_div_2 h2{
    color: #066fb2;
}
.conatct_div_2{
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
    width: 50%;
    padding: 30px;
}


  .conts_related_div {
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    height: auto; 
    padding: 20px;
    background-color: aliceblue;
}
.sale_main_div{
    text-align: center;
}
.sale_div_sub{
    display: flex;
    gap:16%;
    padding: 20px;
}
.sale_din_pic img{
    width: 70%;
    height: auto;
}
.sale_team_div{
    width: 90%;
    margin-left: 10%;
}
.sale_team_div p{
    color: black;
}
.support_team {
    text-align: center;
}
.support_div_1 img{
    width: 50%;
}
.support_main_div{
    display: flex;
}
.support_sub_div {
    margin-right: 10%;
    width: 70%;
}
.sale_div_1 h2{
    font-size: 35px;
    color: #066fb2;
}
.support_team h2{
    font-size: 35px;
    color: #066fb2;
}


@media screen and (max-width: 768px) {
  .contact_div_1_main,
  .conatct_div_2 {
    padding: 15px;
    display: grid;
    width: 95%;
  }

  .conatct_div_2 {
      box-shadow: none; /* Removed box-shadow for mobile view */
      background-color: transparent; /* Removed background color for mobile view */
  }

  .sale_div_sub,
  .support_sub_div {
      gap: 10px; /* Adjusted gap for mobile view */
      display: grid;
  }
  .support_sub_div{
    width: 90%;
  }
  .support_main_div {
    width: 100%;
    display: grid;
    margin-left: 10%;
  }
  body{
    overflow-x: hidden;
  }
  .conatct_sub_div img{
    height: 200px;
  }
  .conts h2{
    font-size: 25px;
    margin-top: 26px;
  }
}



.content-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white; /* adjust text color as needed */
}
.content-overlay h1{
  font-size: 50px;
}


