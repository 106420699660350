/* CSS for footer */
footer {
  background-image: url('../sub_image/Wariere\ Inc.\ \(3\).png');
  background-size: 100% 100%;
  background-position: center;
  padding: 50px ;
}
.footer_main_div{
  display: flex;
  gap:10%;
}
.footer-copyright {
  margin-top: 20px;
}
.footer-menu h2{
  color: #fff;
  font-size: 20px;
}
.foot_sub_head p{
  color: #fff;
}

.foot_sub_head h2{
  font-size: 15px;
}

.foot_sub_head{
  display: grid;
  gap: 15px;
}
/* .footer_menus{
  display: grid;
  gap:15px;
} */
.footer_menus p{
 color: #fff; 
}
.footer_menus label{
  color: #ffffff;
}
.footer_menus h2{
  color: #ffffff;
}
/* .offz_sub_head{
  display: grid;
} */
.offz_sub_head h2{
  font-size: 25px;
  color: #ffffff;
}
.offz_sub_head p{
  font-size: 15px;
  color: #ffffff;
}

.branches p{
  font-size: 15px;
  color: #ffffff;
}
.foot_sub_head input{
  width:90%;
  height: 30px;
  border-radius: 5px;
  border: none;
  padding: 5px 10px;
  font-size: 12px;
  margin-top: 10px;
}
.foot_sub_head select{
  width: 96%;
  height: 40px;
  border-radius:6px;
  padding: 5px 5px;
  margin-top: 10px;
}
.foot_sub_head textarea{
  width: 96%;
  height: 70px;
  border-radius: 6px;
  margin-top: 10px;
}
.foot_sub_head label{
  color: #ffffff;
}
.enquri_btn button{
  padding: 8px 18px;
  border-radius: 2px;
  border: none;
  color: #fff;
  background: royalblue;
  margin-top: 15px;
  cursor: pointer;
}


@media screen and (max-width: 768px) {
  footer {
    padding: 30px; /* Adjusted for mobile view */
  }

  .footer_main_div {
    flex-direction: column; /* Adjusted for mobile view */
    align-items: center; /* Center items for mobile view */
  }

  
  .offz_sub_head,
  .branches {
    text-align: justify; /* Center text for mobile view */
  }

  
  
  .offz_sub_head p,
  .branches p {
    font-size: 13px; /* Adjusted font size for mobile view */
  }
 .footer_menus{
  margin-left: -155px;
  margin-top: 25px;
 }
 .offz_footer{
  margin-left: -40px;
 }
 .offz_sub_head h2{
font-size: 20px;
font-weight: 500;
 }
 .footer_menus h2{
  font-size: 20px;
font-weight: 500;
 }
 .footer_menus p{
  font-size: 13px;
  color: #fff;
 }
 .foot_final {
  height: auto; /* Adjust height based on content */
  padding: 10px; /* Add padding for better spacing */
}

.foot_final h2 {
  font-size: 12px; /* Decrease font size for smaller screens */
  padding-top: 5px; /* Adjust padding for smaller screens */
}
.offz_sub_head{
  margin-left: 40px;
}

}


.foot_final{
  text-align: center;
  background-color: #1842b6 ;
  height: 30px;
}
.foot_final h2{
  font-size: 12px;
 padding-top: 8px;
 font-family: "Poppins", Sans-serif;
 color: #fff;
 font-weight: 400;
}

@media only screen and (max-width: 768px) {
  .foot_final {
      padding: 10px 0; 
  }

  .foot_final h2 {
      font-size: 0.8rem; 
  }
}


.social-icons {
 
  display: flex;
  gap:20px;
}
.social-icons a{
  font-size: 25px;
  color: #fff;
}


