.topic_mian_div img{
    width: 100%;
    height: 450px;
}


.topic_deleted_div {
    display: flex;
    gap:20px;
   padding: 50px;
   
}
.topic_slt h2{
    color: #066fb2;
   
   
}
.topic_deleted_div p{
    width: 90%;
    text-align: justify;
    line-height: 1.9;
    font-size: 15px;
    font-family: "Poppins", Sans-serif;
 
   
}
.topic_pic img{
    width: 125%;
    height: auto;
    margin-left: -100px;
}
.tpc{
    padding: 50px;
   margin-top: -10%;
}
.tpc p{
    text-align: justify;
    line-height: 1.9;
    font-size: 15px;
    font-family: "Poppins", Sans-serif;
   
}
.topic_sub_content_div{
    padding: 50px;
    margin-top: -110px;
}
.topic_sub_content_div p{
    text-align: justify;
    font-size: 15px;
    line-height: 1.9;
    font-family: "Poppins", Sans-serif;
   
}
.slt_pic{
    width: 80%;
}
.slt_pic img {
    width: 100%;
    height: auto;
}
.tpoic_selecte_sub_div{
 margin-top: -100px;
    padding: 50px;
   
    font-size: 14px;
    width: 100%;
    
}

.tpic_seleted_content_div h2{
    color: #066fb2;
    font-size: 20px;
    font-weight: 400;
    
}
.tpic_seleted_content_div ,.phd_tpic_slt ,.tpoic_div_cnt_div ,.topic_final_div li{
    text-align: justify;
    font-size: 15px;
    font-weight: 400;
    line-height: 1.9;
    font-family: "Poppins", Sans-serif;
   
}

.phd_tpic_slt h2{
    color: #066fb2;
    font-size: 20px;
    font-weight: 400;
}
.tpoic_div_cnt_div{
    padding: 50px;
    margin-top: -100px;
   
   
}
.tpoic_div_cnt_div h2{
    color: #066fb2;
    font-size: 20px;
    text-align: justify;
    font-weight: 400;
}
.topic_final_div{
    padding: 50px;
    margin-top: -50px;
    line-height: 30px;
    font-size: 14px;
}
.topic_final_div h2{
    color: #066fb2;
    font-size: 20px;
    font-weight: 400;
}
.tyop_pic{
    display: flex;
    gap:20px
}
.top_div{
    width: 100%;
}
.top_div img{ 
    width: 150%;
    margin-top: 0%;
    margin-left: -20%;
}
@media (max-width: 768px) {
    .topic_main_div img {
        height: auto;
    }
    
    .topic_pic img {
        width: 100%;
        margin-left: 0;
    }
    
    .tpc,
    .topic_sub_content_div,
    .tpic_selected_content_div,
    .phd_tpic_slt,
    .topic_div_cnt_div,
    .topic_final_div {
        
        margin-top: 0;
    }
    .topic_deleted_div{
        display: grid;
        padding: 30px;
       gap:0px;
    }
    .topic_deleted_div p {
        width: 100%;
    }
    .tpc{
        padding: 30px;
        margin-top: -60px;
    }
    .topic_sub_content_div{
        margin-top: -15%;
        padding: 30px;
    }
    .tpoic_selecte_sub_div, .tpoic_div_cnt_div{
        padding: 30px;
        margin-top: -20%;
    }
    .topic_final_div{
        padding: 30px;
    }
    .tyop_pic {
        flex-direction: column; /* Stack items vertically on smaller screens */
        gap: 10px; /* Reduce the gap between items */
    }

    .top_div img {
        max-width: 100%; /* Ensure images don't exceed the width of their container */
        height: auto; /* Maintain aspect ratio */
        margin-top: 0%;
        margin-left: 0%;
    }
    .topic_mian_div img{
        height: 200px;
    }
    .tpic_seleted_content_div h2, .phd_tpic_slt h2, .tpoic_div_cnt_div h2, .topic_final_div h2{
        font-size: 18px;
        font-weight: 400;;
    }
  .to_lo_pi{
    display: grid !important;
  }
 .tpoic_selecte_sub_div{
    width: 82%;
 }
 .tpoic_div_cnt_div h2{
    margin-top: 15px;
 }
}

.body{
    overflow-x: hidden;
}

.to_lo_pi{
    display: flex;
   gap:10px;
}

.to_pic_lo_vi{
    width: 100%;
}
.to_pic_lo_vi img{
    width: 100%;
}

