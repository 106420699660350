.plagiarism_main_div_1 img{
    width: 100%;
}
.plagiarism_main_div{
    display: flex;
    padding: 50px;
    gap:20px;
}
.plagiaris_suv_div{
    width: 100%;
}
.plagiaris_suv_div, .plagrisam_sub_cont,.Plagiarism_final p{
    text-align: justify;
    line-height: 1.9;
    font-size: 15px;
     font-family: "Poppins", Sans-serif;
}
.plagrisam_sub_cont{
    padding: 50px;
    margin-top: -5%;
}
.Plagiarism_card_main{
    display: grid;
    gap:10px;
    padding: 50px;
    margin-top: -5%;
}
.Plagiarism_card_div{
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    padding: 20px;
    border-radius: 6px;
}
.Plagiarism_card_div li{
    text-align: justify;
    line-height: 1.9;
    font-size: 15px;
     font-family: "Poppins", Sans-serif;
}
.Plagiarism_card_div h2{
    font-size: 20px;
    font-weight: 400;
    color: #066fb2;
}
.plagr_final_div{
    padding: 50px;
    margin-top: -5%;
}
.plagr_final_div{
    display: flex;
    gap:20px;
}
.plag_pic img{
    width: 100%;
   
}
.Plagiarism_final{
    width: 100%;
}

@media only screen and (max-width: 768px) {
    .plagiarism_main_div, .plagrisam_sub_cont  {
        padding: 30px; /* Reduced padding for smaller screens */
    }

    .Plagiarism_card_main {
        flex-direction: column;
        gap: 10px;
        padding: 20px; /* Adjust padding for smaller screens */
    }

    .plagr_final_div {
        padding: 30px; /* Adjust padding for smaller screens */
        margin-top: -5%;
    }
    .plagiarism_main_div{
        display: grid;
    }
    .plagr_final_div {
        flex-direction: column; /* Stack items vertically on smaller screens */
        gap: 10px; /* Reduce the gap between items */
    }

    .plag_pic img {
        margin-top: 10px; /* Adjust margin-top for better spacing */
    }
    .pla_he_co_pic img{
        height: 200px !important;
    }
    .plagrisam_sub_cont{
        margin-top: -15%;
    }
}
.pla_he_co_pic img{
    width: 100%;
    height: 450px;
}
.body{
    overflow-x: hidden;
}