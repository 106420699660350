.literature_main_div{
    padding: 50px;
    margin-top: -5%;
}
.literature_pic img{
    width: 100%;

}
.literature_main_div{
    display: flex;
    gap:20px;
}
.Literature_main {
    width: 100%;
}
.Literature_main, .literature_sub_div, .litr_div,.lit_li_lo p{
    text-align: justify;
    font-size: 15px;
    font-family: "Poppins", Sans-serif;
    line-height: 1.9;
}
.literature_sub_div, .lite_fl_div{
    padding: 50px;
    margin-top: -5%;
}
.literature_sub_div, .final_div_lit li{
    text-align: justify;
    font-size: 15px;
    font-family: "Poppins", Sans-serif;
    line-height: 1.9;
}
.literature_sub_div h2 , .final_div_lit h2{
    font-size: 20px;
    font-weight: 400;
    color: #066fb2;
}
.final_card_div_lit{
    display: grid;
    gap:20px;
    padding: 50px;
    margin-top: -5%;
}
.final_div_lit{
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    width: 100%;
    padding: 20px;
    border-radius: 6px;
}
.lite_fl_div{
    display: flex;
    gap:20px;
}

@media only screen and (max-width: 768px) {
    .literature_main_div,
    .literature_sub_div,
    .final_card_div_lit {
        padding: 20px; /* Reduced padding for smaller screens */
    }

    .literature_pic img {
        width: 100%; /* Make image responsive on smaller screens */
        margin-top: 10%; /* Adjusted margin-top for smaller screens */
    }

    .literature_main_div {
        display: flex;
        flex-direction: column; /* Change to column layout for smaller screens */
        gap: 20px; /* Adjusted gap for smaller screens */
    }

    .Literature_main {
        width: 100%; /* Adjusted width for full width on smaller screens */
    }

    .literature_sub_div {
        padding: 30px; /* Reduced padding for smaller screens */
        margin-top: -5%; /* Adjusted margin-top for smaller screens */
    }

    .final_card_div_lit {
        display: flex;
        flex-direction: column; /* Change to column layout for smaller screens */
        gap: 20px; /* Adjusted gap for smaller screens */
    }

    .final_div_lit {
        padding: 10px; /* Adjusted padding for smaller screens */
        border-radius: 6px;
        margin-left: -10px;
    }

    .final_div_lit li {
        font-size: 16px; /* Decreased font size for smaller screens */
    }
    .lite_fl_div{
        display: grid;
        padding: 20px;
    }
    .lite_pid_pic img{
        width: 100%;
    }
    .litr_div{
        padding: 0px;
    }
    .lit_div_pic img{
        height: 200px !important;
    }
  
}
.lit_div_pic img{
    width: 100%;
    height: 450px;
}

.lit_li_lo{
    padding: 50px;
}


@media only screen and (max-width: 768px) {
    .lit_li_lo {
        padding: 20px;
        border-radius: 0;
    }

    .lit_li_lo h2 {
        font-size: 1.5rem; /* Adjust font size for smaller screens */
        margin-bottom: 10px;
    }

    .lit_li_lo p {
        margin-bottom: 10px;
    }
}

.body{
    overflow-x: hidden;
}