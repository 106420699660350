.madelab_pic img{
    width: 100%;
    height: 450px;
}
.lab_main_div{
    
    width: 100%;
}
.lab_main_div, .mate_co_la p{
    line-height: 1.9;
    text-align: justify;
 font-family: "Poppins", Sans-serif;
    font-size: 15px;
}
.mate_div_lab_1 {
    display: flex;
    gap:10%;
}
.mate_div_lab_1, .made_made_div li{
    line-height: 1.9;
    text-align: justify;
 font-family: "Poppins", Sans-serif;
    font-size: 15px;

}
.mate_hes{
    padding: 50px;
    margin-top: -5%;
}
.made_div_sub_cont_1{
    padding: 50px;
    margin-top: -5%;
}
.made_made_div{
    padding: 50px;
    margin-top: -5%;
}
.made_div_sub_cont_1 p{
    line-height: 1.9;
    text-align: justify;
 font-family: "Poppins", Sans-serif;
    font-size: 15px;
}
.made_div_sub_cont_1 h2{
    font-size: 20px;
    color: #066fb2;
    font-weight: 400;
}
.mate_hes h2{
    font-size: 20px;
    color: #066fb2;
    font-weight: 400;
}
.lab_main_div h2{
   
    color: #066fb2;
}
.made_made_div h2{
    font-size: 20px;
    color: #066fb2;
    font-weight: 400;
}

.lab_dis_div {
    display: flex;
    gap:20px;
    padding: 50px;
}
.mate_co_la {
    padding: 50px;
    margin-top: -5%;
}
.mat_pic img{
   margin-top: 50px;
    margin-left: 10%;
    width: 100%;
}
.mate_lab_div_1{
    display: flex;
    gap:10px;
}

@media (max-width: 768px) {
    .mate_div_lab_1 {
        flex-direction: column;
        gap: 2%;
    }

    .lab_dis_div {
        gap: 1%;
        display:grid ;
        padding: 20px;
    }

    .mat_pic img {
        margin-top: 0%;
        margin-left: -20px;
        width: 100%;
        padding: 20px;
    }
    .mate_hes{
        width: 100%;
    }
    .mate_hes, .made_div_sub_cont_1, .made_made_div {
        padding: 30px;
        margin-top: -5%;
    }
    .mate_hes h2 {
        margin-top: 15%;
    }
    .lab_main_div{
        
        width: 100%;
    }
    .mate_div_pic img{
        width: 100%;
    }
    .mate_lab_div_1{
        display: grid;
    }
    .madelab_pic img{
        height: 200px;
    }
    .mate_co_la{
        padding: 30px;
    }
}

.body{
    overflow-x: hidden;
}