.about_div{
    padding: 10px;
}
.about_main img {
    width: 100%;
    height: 450px;
}
.who_we h2{
    text-align: center;
}
.Ab{
    text-align: center;
    font-size: 35px;
    color: #066fb2;
}
.wee_main_div img {
    width: 80%;
}
.wee_main_div {
    position: relative;
}
.content-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: white; /* adjust text color as needed */
  }
  .content-overlay h1{
    font-size: 50px;
  }
.wee_are{
    display: flex;
    gap:20px;
}
.weee_sub_div{
    padding: 50px;
    
}
.weee_sub_div h2{
color: #066fb2;
text-align: center;

}
.weee_sub_div p{
    text-align: justify;
    color: #687076;
    font-family: "Poppins", Sans-serif;

    font-size: 15px;
    font-weight: 400;
    line-height: 1.9;
}
.confidentiality{
   background-color: #f2f0f0;
}
.about_confitend img{
    width: 100%;
}
.confidentiality{
    display: flex;
}
.confitend p{
    text-align: justify;
    color: black;
    font-family: "Poppins", Sans-serif;
  
    font-size: 15px;
    font-weight: 400;
    line-height: 1.9;

}
.confitend{
    padding: 50px;
   
    
}
.confitend h2{
    color: #066fb2;
    text-align: center;
    
}
.vision_main_div h2{
    text-align: center;
    color: #ffffff;
    
}
.vision_main_div, .mision p{
    color: #ffffff;
    text-align: justify;
    font-family: "Poppins", Sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 1.9;
    padding: 10px;
}
.mision h2{
    text-align: center;
    color: #ffffff;
    margin-top: 10px;
}
.vision {
    background-image: url('../anime//blue\ 1.avif');
    background-size: cover; /* Adjusts the background image to cover the entire container */
    background-position: center; /* Centers the background image */
    /* Add any other styles as needed */
}
.vision_main_div{
    padding: 10px;
}
.three_main_div{
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    width: 50%;
    padding: 20px;
    border-radius: 6px;
    background-color: #fff;
   
}
.three_card_div {
    display: flex;
    gap:20px;
    padding: 20px;
}
.three_main_div h3{
    font-size: 15px;
    color:black;
    font-family: "Poppins", Sans-serif;
    text-align: justify;
    line-height: 1.9;
    font-weight: 400;
}
.three_main_div p{
    text-align: justify;
    color:black;
    font-family: "Poppins", Sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 1.9;
}
.three_main_div h2{
    font-size: 20px;
    color: #066fb2;
}
.core_value {
    padding: 20px;
}

.core_value {
    background-image: url('../anime//blue3.avif');
    background-size: cover; /* Adjusts the background image to cover the entire container */
    background-position: center; /* Centers the background image */
    /* Add any other styles as needed */
}
.core_main_div h2{
    color: #fff;
    text-align: center;
}
.core_main_div p{
    color: #fff;
    text-align: center;
    font-family: "Poppins", Sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 1.9;
}




@media screen and (max-width: 768px) {
    .about_main img {
        height: 200px; /* Adjusted for mobile view */
    }

    .wee_main_div img {
        width: 100%; /* Adjusted for mobile view */
    }

    .three_main_div {
        width: 80%; /* Adjusted for mobile view */
    }
    .three_card_div{
        width: 100%;
        display: grid;
        gap:20px;
    }
    .wee_are{
        display: grid;
        gap:20px;
    }
    .confidentiality{
        display: grid;
        gap:20px;
    }
    body{
        overflow-x: hidden;
    }
    .weee_sub_div, .confitend{
        padding: 20px;
    }
    .vis_di{
        flex-direction: column-reverse;
    }
}

.vis_di{
    display: flex;
    gap: 20px;
    width: 100%;
}
.mision{
    width: 100%;
}


@media (max-width: 768px) {
    .content-overlay {
      width: 90%;
      font-size: 16px; /* Adjust font size for mobile */
    }
  
    .content-overlay h1 {
      font-size: 25px; /* Adjust h1 font size for mobile */
      margin-top: -250px;
    }
  }

  