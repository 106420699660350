.propsal_main_div img{
    width:100%;
    height: 450px;
}
.proposal_div{
    display: flex;
    gap:10px;
    padding: 50px;
}
.proposal_div_1{
    width: 100%;
}
.proposal_div_1 p{
    text-align: justify;
    line-height: 1.9;
    font-size: 15px;
     font-family: "Poppins", Sans-serif;
}
.proposal_pic img{
    width: 100%;
    margin-left: 15%;
}
.proposal_div_1 li{
    line-height: 1.9;
    font-size: 15px;
    text-align: justify;
     font-family: "Poppins", Sans-serif;
}
.pro_sub_div_1 img{
    width: 100%;
    height: auto;
}
.pro_div_content li{
   line-height: 1.9;
    font-size: 15px;
    text-align: justify;
     font-family: "Poppins", Sans-serif;
}
.pro_div_main{
    display: flex;
    gap:10px;
    padding: 50px;
}
.proposal_div_1 h2{
    color: #066fb2;
}
.pro_div_content h2{
    color: #066fb2;
    font-size: 20px;
    font-weight: 400;
}

.pro_div_content_1 h2{
    color: #066fb2;
    font-weight: 400;
}
.pro_div_content_1 ,.pro_div_content {
    line-height: 30px;
}
.pro_div_content_1 li{
    text-align: justify;
    font-size: 15px;
    font-family: "Poppins", Sans-serif;
}
.proposal_final{
    padding: 50px;
    margin-top: -5%;
}
.proposal_final h2 {
  font-size: 20px;
   color: #066fb2;
   font-weight: 400;
}
.proposal_final li{
    line-height: 1.9;
    font-size: 15px;
    text-align: justify;
     font-family: "Poppins", Sans-serif;
}
.proposal_final p{
    line-height: 1.9;
    font-size: 15px;
    text-align: justify;
     font-family: "Poppins", Sans-serif;
}
.pro_sub_div_1{
    width: 100%;
}


@media (max-width: 768px) {
    .propsal_main_div img {
        height: auto;
    }
.pro_div_content_1{
    padding: 0px;
}
    .proposal_pic img {
        width: 100%;
        margin-left: 0;
    }
  

    .proposal_div,
    .pro_div_main,
    .proposal_final {
        flex-direction: column;
        padding: 20px;
    }

    .proposal_div_1,
    .proposal_pic,
    .pro_sub_div_1 {
        width: 100%;
    }

    .proposal_div_1 {
        padding: 0;
    }

    .proposal_div_1 p,
    .proposal_div_1 li,
    .pro_div_content li,
    .pro_div_content_1 li,
    .proposal_final li {
        line-height: 1.9;
        font-size: 15px;
        text-align: justify;
         font-family: "Poppins", Sans-serif;
    }
    .proposal_final p{
        line-height: 1.9;
        font-size: 15px;
        text-align: justify;
         font-family: "Poppins", Sans-serif;
    }
    .proposal_final{
        margin-top: -15%;
    }
}

.body{
    overflow-x: hidden;
}