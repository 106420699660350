
.admin_head{
    width: 100%;
    height: 60px;
    background-color: #fff;
    display: flex;
    gap:20px;
    position: fixed;
    top:0;
    box-shadow: 2px 1px 4px skyblue;
  }
  .ad_hed_1 {
   display: flex;
   padding: 20px;
    
   width: 150%;
  }

  .ad_logo img{
    width: 20%;
    margin-top: -2%;
    margin-left: 20px;
  }
  .ad_hed_1 link{
    color: #fff;
    
  }
  .menu-icon {
    display: none;
  }
  .ad_hed_1 a {
    color: #fff;
  }
  
  @media (max-width: 768px) {
    .ad_logo img {
      width: 70%; 
      margin-top: -15px;
      margin-left: 5px;
    }
  
    .ad_hed_1 {
      display: flex; 
      width: 50%; 
      justify-content: space-between; 
      align-items: center; 
    }
    
    .desktop-menu {
        display: none; 
      }
    
      .menu-icon {
        display: block; /* Show the menu icon on mobile view */
        margin-right: 0px; /* Adjust margin as needed */
        background: none;
        border: none;
        cursor: pointer;
        color: darkblue; /* Set the icon color */
        font-size: 20px; /* Adjust icon size as needed */
      }
    
      .mobile-menu-dropdown {
        position: absolute;
        top: calc(80% + 12px);
        right: 5px;
        background-color: #0b78ff;
        padding: 10px;
        border-radius: 6px;
        display: grid;
        z-index: 999;
      }
    
      .mobile-menu-dropdown a {
        margin-bottom: 10px;
        color: #fff;
        text-decoration: none;
      }
      .menu-icon-bars {
        width: 30px;
        height: 3px;
        background-color: #fff;
        margin: 6px 0;
        
      }
      
      
  }

 