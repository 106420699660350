#progress{
    background-color: royalblue;
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 50px;
    height: 50px;
    display: grid;
    place-items: center;
    border-radius: 50%;
    box-shadow: 0 0 10px rgba(0,0,0,0.2);
    cursor: pointer;
}
#progress_value{
    display: block;
    height: calc(100% - 15px);
    width: calc(100% - 15px);
    background-color: #fff;
    border-radius: 50%;
    display: grid;
    place-items: center;
    font-size: 25px;
    color: #001a2e;
}