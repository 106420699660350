 .change_pas {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    width: 100%;
    text-align: center;
    margin-top: 10%;
    margin-left: 30%;
  }
  
  h2 {
    margin-top: 0;
  }
  
  input[type="password"] {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border-radius: 4px;
    border: 1px solid #ccc;
    box-sizing: border-box;
  }
  
  button {
    background-color: #007bff;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  button:hover {
    background-color: #0b78ff;
  }
  

  @media screen and (max-width: 600px) {
    .change_pas {
        padding: 20px;
        margin-left: 20px;
        width: 80%;
        margin-top: 40%;
    }
    
    input[type="password"] {
      padding: 8px; 
      margin: 8px 0; 
    }
  
    button {
      padding: 8px 16px; 
    }
    
  }