.synposis_pic img{
    width: 100%;
    height: 450px;
}
.synposis_content_div {
    display: flex;
    padding: 50px;
    margin-top: -5%;
}
.synopsis_pic img {
    width: 100%;
    
}
.synopsis_sub_content_div{
    padding: 50px;
    margin-top: -5%;
}
.synopsis_sub_content_div, .synopsis_content_div_1,.syno_di p{
    line-height: 1.9;
    text-align: justify;
    font-family: "Poppins", Sans-serif;
    font-size: 15px;
}
.synopsis_content_div_1{
    width: 100%;
}
.synopsis_con_sub_div{
    padding: 50px;
    margin-top: -5%;
}
.synopsis_content_div_1, .syno_di h2{
   
    color: #066fb2;
}
.synopsis_content_div_1 h2{
    font-weight: 400;
}
.synopsis_con_sub_div h2{
    font-size: 20px;
    color: #066fb2;
    font-weight: 400;
}
.synopsis_con_sub_div p{
    line-height: 1.9;
    text-align: justify;
    font-family: "Poppins", Sans-serif;
    font-size: 15px;
}
.synopsis_con_sub_div_1 h2{
    font-size: 20px;
    color: #066fb2;
    font-weight: 400;
}

.synopsis_con_sub_div_1 p{
    line-height: 1.9;
    text-align: justify;
    font-family: "Poppins", Sans-serif;
    font-size: 15px;
}
.synopsis_content_div_1 li{
    font-size: 15px;
    line-height: 1.9;
    font-family: "Poppins", Sans-serif;
    text-align: justify;
    color: black;
}
.syno_fl_div{
    display: flex;
    gap:20px;
    padding: 50px;
    margin-top: -5%;
}
.syno_pic_div{
    width: 100%;
}
.syno_pic_div img{
    width: 100%;
   
}
.synopsis_con_sub_div_1{
    width: 100%;
}

@media only screen and (max-width: 768px) {
    .synopsis_pic img {
        height: auto; /* Adjust image height to maintain aspect ratio */
       
    }

    .synposis_content_div{
        display: grid;
        padding: 30px;
    }
    .synopsis_sub_content_div,
    .synopsis_con_sub_div,
    .syno_fl_div
     {
        padding: 20px; /* Adjust padding for smaller screens */
    }

    .synopsis_con_sub_div h2,
    .synopsis_con_sub_div_1 h2 {
        font-size: 18px; /* Decrease font size for smaller screens */
        font-weight: 400;
    }

    .synopsis_sub_content_div p,
    .synopsis_con_sub_div p,
    .synopsis_con_sub_div_1 p {
        font-size: 15px; /* Decrease font size for smaller screens */
    }
    .syno_fl_div {
        flex-direction: column; /* Stack items vertically on smaller screens */
        padding: 20px; /* Decrease padding for better spacing on smaller screens */
        gap: 10px; /* Reduce the gap between items */
    }

    .syno_pic_div img {
        margin-top: 10px; /* Adjust margin-top for better spacing */
    }
    .synposis_pic img{
        height: 200px;
    }
   
   
}

.syno_di{
    padding: 50px;
}


@media only screen and (max-width: 768px) {
    .syno_di {
        padding: 20px;
    }
}

.body{
    overflow-x: hidden;
}