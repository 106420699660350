.grammer_main_div{
    padding: 50px;
}
.grammer_main_div , .gramer_sub_content_div, .grammer_final_div p{
    text-align: justify;
    line-height: 1.9;
    font-size: 15px;
        font-family: "Poppins", Sans-serif;
}
 .gramer_sub_content_div h2, .grammer_final_div h2{
    font-size: 20px;
    font-weight: 400;
}
.grammer_pic img{
    width: 100%;
    border-radius: 6px;
}
.grammer_pic{
    width: 100%;
}
.grammer_sub_div{
    display: flex;
    gap:20px;
    padding: 50px;
    margin-top: -5%;
}
.grammer_sub_div_1{
    width: 100%;
}
.grammer_sub_div_1 li{
    font-size: 15px;
        font-family: "Poppins", Sans-serif;
    line-height: 1.9;
    text-align: justify;
}
.gramer_sub_content_div{
    padding: 50px;
    margin-top: -5%;
}
.gram_fl_div{
    padding: 50px;
    margin-top: -5%;
    display: flex;
    gap:20px
}

.grammer_final_div li{
    font-size: 15px;
        font-family: "Poppins", Sans-serif;
    line-height: 1.9;
    text-align: justify;
}

@media only screen and (max-width: 768px) {
    .grammer_main_div,
    .gramer_sub_content_div,
    .gram_fl_div {
        padding: 30px; /* Reduced padding for smaller screens */
    }

    .grammer_sub_div {
        display: flex;
        flex-direction: column; /* Change to column layout for smaller screens */
        gap: 10px; /* Adjusted gap for smaller screens */
        padding: 30px; /* Reduced padding for smaller screens */
        margin-top: -15%; /* Adjusted margin-top for smaller screens */
    }

    .grammer_sub_div_1 {
        width: 100%; /* Adjusted width for full width on smaller screens */
    }

    .grammer_final_div li,
    .grammer_sub_div_1 li {
        font-size: 16px; /* Decreased font size for smaller screens */
    }
    .gram_pic img{
        height: 200px !important;
    }
    .gram_fl_div{
        display: grid;
    }
}

.gram_pic img{
    width: 100%;
    height: 450px;
}
.body{
    overflow-x: hidden;
}